import React, { useEffect, useState } from "react";
import { useLazyGetActivityHistoryByIdQuery } from "../../services/users";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const PurchaseHistory = () => {
  const { _id } = useParams();
  const [rows, setRows] = useState<any>([]);

  const [getActivityHistoryById] = useLazyGetActivityHistoryByIdQuery();

  const fetchUserBooking = async () => {
    try {
      const result = await getActivityHistoryById({
        userId: _id,
        bookingType: "purchase",
      }).unwrap();
      if ("data" in result && result.statusCode === 200) {
        const mappedBookings = result.data.booking.map((booking: any) => ({
          id: booking._id,
          bookingNumber: booking.bookingNumber,
          className: booking.subjectspecializations[0]?.name,
          bookingCost: booking.grandTotal,
          date: new Date(booking.bookingdetails[0]?.date).toLocaleDateString(),
          time: new Date(booking.bookingdetails[0]?.date).toLocaleTimeString(),
          activeStatus: mapBookingStatus(booking.bookingStatus),
        }));
        setRows(mappedBookings);
      } else {
        console.error("Failed to fetch user bookings:", result);
        setRows([]); // Reset rows to an empty array if there's an error
      }
    } catch (error) {
      console.error("Error fetching user bookings:", error);
    }
  };

  const mapBookingStatus = (bookingStatus: number) => {
    switch (bookingStatus) {
      case 1:
        return "PENDING";
      case 2:
        return "ACCEPTED";
      case 3:
        return "COMPLETED";
      case 4:
        return "REJECTED";
      case 5:
        return "CANCELLED";
      case 6:
        return "ONGOING";
      case 7:
        return "UPCOMING";
      default:
        return "UNKNOWN";
    }
  };

  useEffect(() => {
    if (_id) fetchUserBooking();
  }, [_id]);

  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">Purchase History</h2>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Booking id</TableCell>
              <TableCell>Class Name</TableCell>
              <TableCell>Booking cost</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Active Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? ( // Check if rows array is empty
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography style={{marginLeft:400}}>No purchase history available</Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((booking: any, index: number) => (
                <TableRow key={booking.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{booking?.bookingNumber||"-"}</TableCell>
                  <TableCell>{booking?.className||"-"}</TableCell>
                  <TableCell>${booking?.bookingCost?.toFixed(2)||"-"}</TableCell>
                  <TableCell>{booking?.date||"-"}</TableCell>
                  <TableCell>{booking?.time||"-"}</TableCell>
                  <TableCell>{booking?.activeStatus||"-"}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PurchaseHistory;
