import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import MainContainer from "../../../layout/MainContainer";
import { DocumentViewer } from "../../../components";
import {
  Challenges,
  Details,
  Earning,
  Journeys,
  PostsAndStories,
  TutorRating,
} from "../../../features";
import {
  useLazyGetTeachingDetailsByIdQuery,
  useLazyGetTutorDetailsQuery,
  useLazyGetnextTutorByIdQuery,
  useLazyGetutorByIdQuery,
} from "../../../services/tutor";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";

import Loader from "../../../helpers/Loader";
import { TutorDetails } from "../../../types/General";
import { useLazyGetDocucmentsDetailsQuery } from "../../../services/documentation";
import moment from "moment";
import { errorToast } from "../../../helpers";

import ActivityHistoryTutor from "../../../features/tutor/ActivityHistory";
import { truncate } from "fs/promises";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TutorDetail = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [img, setImg] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [ByIdDetails, setByIdDetails] = useState<any>();
  const [nextTutordata, setNextTutordata] = useState<any>([]);
  const [getTutorFetchById] = useLazyGetutorByIdQuery();
  const [data, setData] = useState<any>([]);
  console.log("3iji", ByIdDetails);
  console.log("ByIdDetails", ByIdDetails);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [getTutorById, { isLoading }] = useLazyGetTutorDetailsQuery();
  const [nextTutor, setNextTutor] = useLazyGetnextTutorByIdQuery();
  const { _id } = useParams();
  const [getTeachingDetails] = useLazyGetTeachingDetailsByIdQuery();
  const [getDocs] = useLazyGetDocucmentsDetailsQuery();
  // const [getDetials]
  const fetchDataById = async (_id: any) => {
    try {
      const response = await getTutorById({ id: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const nextById = async (_id: any) => {
    try {
      const response = await nextTutor({ userId: _id }).unwrap();
      console.log("Fetched next tutor details:", response);
      if (response.statusCode === 200) {
        setNextTutordata(response?.data);
        // Navigate to the next tutor's profile page using `navigate`
        // navigate(`/next-tutor-detail/${response?.data?._id}`);
        navigate(
          `/manage-tutor/details/NextTutorDetail/${response?.data?._id}`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTeaching = async (id: string) => {
    try {
      const res = await getTeachingDetails({
        userId: id,
      }).unwrap();
    } catch (error: any) {
      errorToast("No next tutor available");
      navigate("/manage-tutors");
    }
  };

  const fetchDocs = async (id: string) => {
    try {
      const res = await getDocs({
        userId: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
      getTeaching(_id);
      fetchDocs(_id);
      // nextById(_id)
    }
  }, [_id]);
  const val = data?.findIndex(
    (item: { documentType: number }) => item?.documentType === 2
  );
  const idIndex = data?.findIndex(
    (item: { documentType: number }) => item?.documentType === 4
  );
  const certificates = data?.findIndex(
    (item: { documentType: number }) => item?.documentType === 3
  );
  console.log(val, "val");
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Tutor Profile</h1>
          {/* <Button
            className="btn btn_primary"
            onClick={() => {
          navigate("/manage-tutor/details/NextTutorDetail")
            }}
          > */}

          {_id && (
            <Button className="btn btn_primary" onClick={() => nextById(_id)}>
              Next
            </Button>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-tutors");
            }}
          >
            Back
          </Button>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure
                  className="profile_img"
                  onClick={() => {
                    setOpen(true);
                    setImg(ByIdDetails?.image);
                  }}
                >
                  <img
                    src={
                      ByIdDetails?.image
                        ? ByIdDetails?.image
                        : `/static/images/user_placeholder.png`
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">
                        {ByIdDetails?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">
                        {ByIdDetails?.userName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Las name</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid> */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {ByIdDetails?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        + {ByIdDetails?.phoneNo || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Date Of Birth</Typography>
                      <Typography component="p">
                        {" "}
                        {/* {ByIdDetails?.age ||"-"}{" "} */}
                        {ByIdDetails
                          ? moment(ByIdDetails.age).format("DD-MM-YYYY")
                          : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Gender</Typography>

                      <Typography component="p">
                        {(() => {
                          switch (ByIdDetails?.gender) {
                            case "MALE":
                              return "Male";
                            case "FEMALE":
                              return "Female";

                            default:
                              return "-";
                          }
                        })()}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Total Teaching Experience
                      </Typography>
                      <Typography component="p">
                        {ByIdDetails?.teachingdetails[0]
                          ?.totalTeachingExperience || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Price/hour
                      </Typography>
                      <Typography component="p">
                        {ByIdDetails?.teachingdetails[0]
                          ?.price || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Short Bio</Typography>
                      <Typography component="p">
                        {ByIdDetails?.shortBio || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5"> Address</Typography>
                      <Typography component="p">
                        {ByIdDetails?.address ? ByIdDetails?.address : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">
                        {ByIdDetails?.isBlocked === false
                          ? "Active"
                          : "Blocked"}
                      </Typography>
                    </Box>
                  </Grid>

                  {idIndex !== -1 ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5" style={{ padding: 20 }}>
                          Id Proof
                        </Typography>
                        <Box className="docs_div">
                          <Grid container spacing={2}>
                            {data?.map(
                              (
                                item: {
                                  documentType: number;
                                  frontImage: string | undefined;
                                },
                                index: number
                              ) =>
                                item.documentType === 4 ? (
                                  <Grid item xs={4} key={index}>
                                    {item?.frontImage?.endsWith(".pdf") ? (
                                      <div className="upload_pdf_preview">
                                        <EmbedPDF companyIdentifier="yourcompany">
                                          <a
                                            href={`${item?.frontImage}`}
                                            className="pdf_uplod"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src="/static/images/pdf.png"
                                              alt="img"
                                            ></img>
                                          </a>
                                        </EmbedPDF>
                                      </div>
                                    ) : (
                                      <figure
                                        onClick={() => {
                                          setOpen(true);
                                          setImg(
                                            item?.frontImage
                                              ? item?.frontImage
                                              : ""
                                          );
                                        }}
                                      >
                                        <img
                                          src={item.frontImage}
                                          alt="ID Proof"
                                        />
                                      </figure>
                                    )}
                                  </Grid>
                                ) : null
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  ) : null}

                  {val !== -1 ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5" style={{ padding: 20 }}>
                          Achievements
                        </Typography>
                        <Box className="docs_div">
                          <Grid container spacing={2}>
                            {data?.map(
                              (
                                item: {
                                  documentType: number;
                                  frontImage: string | undefined;
                                },
                                index: number
                              ) =>
                                item.documentType === 2 ? (
                                  <Grid item xs={4} key={index}>
                                    {item?.frontImage?.endsWith(".pdf") ? (
                                      <div
                                        className="upload_pdf_preview"
                                        style={{ marginLeft: "16px" }}
                                      >
                                        <EmbedPDF companyIdentifier="yourcompany">
                                          <a
                                            href={`${item?.frontImage}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="pdf_uplod"
                                          >
                                            <img
                                              src="/static/images/pdf.png"
                                              alt="img"
                                            ></img>
                                          </a>
                                        </EmbedPDF>
                                      </div>
                                    ) : (
                                      <figure
                                        onClick={() => {
                                          setOpen(true);
                                          setImg(
                                            item?.frontImage
                                              ? item?.frontImage
                                              : ""
                                          );
                                        }}
                                      >
                                        <img
                                          src={item.frontImage}
                                          alt="Achievement"
                                        />
                                      </figure>
                                    )}
                                  </Grid>
                                ) : null
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  ) : null}

                  {certificates !== -1 ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5" style={{ padding: 20 }}>
                          Certifications
                        </Typography>
                        <Box className="docs_div">
                          <Grid container spacing={2}>
                            {data?.map(
                              (
                                item: {
                                  documentType: number;
                                  frontImage: string | undefined;
                                },
                                index: number
                              ) =>
                                item.documentType === 3 ? (
                                  <Grid item xs={4} key={index}>
                                    {item?.frontImage?.endsWith(".pdf") ? (
                                      <div
                                        className="upload_pdf_preview"
                                        style={{ marginLeft: "16px" }}
                                      >
                                        <EmbedPDF companyIdentifier="yourcompany">
                                          <a
                                            href={`${item?.frontImage}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="pdf_uplod"
                                          >
                                            <img
                                              src="/static/images/pdf.png"
                                              alt="img"
                                            ></img>
                                          </a>
                                        </EmbedPDF>
                                      </div>
                                    ) : (
                                      <figure
                                        onClick={() => {

                                          setImg(
                                            item?.frontImage
                                              ? item?.frontImage
                                              : ""
                                          );
                                          setOpen(true);
                                        }}
                                      >
                                        <img
                                          src={item.frontImage}
                                          alt="Certification"
                                        />
                                      </figure>
                                    )}
                                  </Grid>
                                ) : null
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                {/* <Tab label="Manual Payouts" {...a11yProps(0)} /> */}
                <Tab label="Earnings" {...a11yProps(0)} />
                <Tab label="Activity History" {...a11yProps(1)} />
                <Tab label="Ratings" {...a11yProps(2)} />
              </Tabs>
            </Box>
            {/* <CustomTabPanel value={value} index={0}>
              <TutorRating />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={0}>
              <Earning />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ActivityHistoryTutor />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TutorRating />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <DocumentViewer open={open} setOpen={setOpen} img={img} />
    </MainContainer>
  );
};
export default TutorDetail;
