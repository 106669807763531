import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import {
  useForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../../helpers";
import { setCredentials, temporaryToken } from "../../../reducers/authSlice";
import { useAppDispatch } from "../../../hooks/store";
import Loader from "../../../helpers/Loader";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { BorderColor } from "@mui/icons-material";

const VerifyOtp = () => {
  const location = useLocation();
 const [wrongOTP,setWrongOTP]=useState(false);
  const dispatch = useAppDispatch();
  const { state } = location;
  console.log("state: ", state);
  const navigate = useNavigate();
  const [timerStartedAt, setTimerStartedAt] = useState<number | null>(() => {
    const storedTimerStartedAt = parseInt(localStorage.getItem("timerStartedAt") || "0");
    const currentTime = Date.now();
    const elapsedTime = Math.floor((currentTime - storedTimerStartedAt) / 1000);
    const remainingTime = Math.max(0, 60 - elapsedTime);
    return storedTimerStartedAt && !isNaN(storedTimerStartedAt) ? storedTimerStartedAt : null;
  });
  
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(59);

  const [optVerificationMutation, { isLoading }] = usePostVerifyOtpMutation();
  const [ForgotPasswordMutation, ForgotPasswordData] =
    useForgotPasswordMutation();

  const [error, setError] = useState<boolean>(false);

  const otpStyle = {
    width: "50px",
    height: "50px",
    "box-sizing": "border-box",
    margin: "0 auto",
    border:(error?"1px solid red":"1px solid green"),
  };
  
  const handleSubmit = async () => {
    setError(true);
    if (otp?.length === 4) {
      setError(false);

      const body = {
        email: state?.email,
        otp: otp,
      };

      if (state?.password) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response =
            await optVerificationMutation(encryptedBody).unwrap();
          setToStorage(
            STORAGE_KEYS.tempToken,
            JSON.stringify(response?.data?.accessToken)
          );
          dispatch(
            setCredentials({
              user: response?.data,
              token: "",
            })
          );
          dispatch(
            temporaryToken({
              tempToken: response?.data?.accessToken || null,
            })
          );
          navigate("/profile-setup", { state: state, replace: true });
        } catch (error: any) {
          setError(true);
          // errorToast(error?.data?.message|| "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response =
            await optVerificationMutation(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            dispatch(
              temporaryToken({ tempToken: response?.data?.accessToken })
            );
            navigate("/resetPassword", { replace: true });
          }
        } catch (error: any) {
          if (error?.data?.message) {
            setError(true);
            // errorToast(error?.data?.message || "");
          }
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    setError(false);
    setOtp(""); // Clear previous OTP
    setTimerStartedAt(Date.now()); // Set timerStartedAt to current time
    localStorage.setItem("timerStartedAt", Date.now().toString()); // Update timerStartedAt in localStorage
    let body = {
      email: state?.email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await ForgotPasswordMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast("OTP Resent Successfully");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => {
        if (prevCountDown <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem("timerStartedAt");
          return 0;
        }
        return prevCountDown - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timerStartedAt !== null) {
        const elapsedTime = Math.floor((Date.now() - timerStartedAt) / 1000);
        const remainingTime = Math.max(0, 60 - elapsedTime); 
        setCountDown(remainingTime);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [timerStartedAt]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timerStartedAt !== null) {
        const elapsedTime = Math.floor((Date.now() - timerStartedAt) / 1000);
        const remainingTime = Math.max(0, 60 - elapsedTime); // Countdown resets to 0 if more than 60 seconds have passed
        setCountDown(remainingTime);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [timerStartedAt]);

  useEffect(() => {
    if (countDown === 0) {
      localStorage.removeItem("timerStartedAt"); // Clear the timerStartedAt value if countdown reaches 0
    } else {
      localStorage.setItem("timerStartedAt", Date.now().toString()); // Update the timerStartedAt value in localStorage
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={ForgotPasswordData?.isLoading || isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
         <figure className="logo_cntnr" style={{ backgroundColor: "white", marginBottom: 10, padding: 10, borderRadius: "20px" }}>
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
        <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
  <IconButton
    onClick={() => navigate("/forgotpassword")}
    sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
  >
    <ArrowBackIcon />
  </IconButton>
  <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
    Verify OTP
  </Typography>
</Box>
<FormControl className="opt_fields" sx={{ width: "100%" }}>
  <OtpInput
    value={otp}
    onChange={(e)=>{
      setError(false);
      setOtp(e);}}
    numInputs={4}
    renderInput={(props) => <input {...props} />}
    inputStyle={otpStyle}
    inputType="tel"
  />
</FormControl>
{error && (
  <Typography sx={{ color: "red", textAlign: "center", mt: 1 }}>
    {otp.length==0?"Please enter OTP":"Incorrect OTP. Please try again."}
  </Typography>
)}
<Box sx={{ pt: 3 }}>
  <Button
    className="btn btn_primary"
    color="primary"
    fullWidth
    size="large"
    type="submit"
    variant="contained"
    onClick={handleSubmit}
  >
    Verify OTP
  </Button>
</Box>

          {countDown === 0 ? (
            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography
                className="anchor_link"
                sx={{ cursor: "pointer" }}
                onClick={handleResendOtp}
              >
                Resend OTP
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                // className="anchor_link"
                sx={{
                  color: "#1d2b28",
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                The verification code will expire in{" "}
                {countDown < 10 ? (
                  <p style={{ margin: 0, color: "#1d2b28", marginLeft: 6 }}>
                    00 : 0{countDown}
                  </p>
                ) : (
                  <p style={{ margin: 0, color: "#1d2b28", marginLeft: 6 }}>
                    00 : {countDown}
                  </p>
                )}
              </Typography>
            </Box>
          )}
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
