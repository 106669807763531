export function formatDateTime(dateData: any) {
  const index = dateData.indexOf("T");
  return dateData.slice(0, index) + " " + dateData.slice(index + 1, index + 6);
}
export function getOnlyDate(dateData: any) {
  const index = dateData.indexOf("T");
  return dateData.slice(0, index);
}
export function getOnlyTimeHHMM(dateData: any) {
  const index = dateData.indexOf("T");
  return dateData.slice(index + 1, index + 6);
}
