import {
  Box,
  Card,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyGetSpecificReviewByIdQuery } from "../../services/review";
import { useParams } from "react-router-dom";

const TutorRating = () => {
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>({});
  const [fetchApi] = useLazyGetSpecificReviewByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      console.log("9090", response);
      if (response?.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  return (
    <Card className="cards">
      <h2 className="mn_hdng" style={{ marginTop: 0 }}>
        Ratings
      </h2>

      <TableContainer className="table_container">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Parent Name</TableCell>
              <TableCell>Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ByIdDetails?.rating?.length > 0 ? (
              ByIdDetails?.rating?.map((item: any, index: number) => (
                <TableRow key={item?._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.parents[0]?.name}</TableCell>
                  <TableCell>
                    <Rating value={item?.avgRating} readOnly />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography style={{marginLeft:400}}>No review for this tutor</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TutorRating;
