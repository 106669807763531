import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useLazyDeleteCustomerSupportByIdQuery,
  useLazyGetAllCustomerQuery,
} from "../../services/customerSupport";
import { errorToast, successToast } from "../../helpers";
import { useParams } from "react-router-dom";
import WarnModal from "../../components/modals/WarnModal";
import { usePostRevertQueryMutation } from "../../services/customerSupport";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../helpers/Loader";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const [revertQuery] = usePostRevertQueryMutation();
  const [revertError, setRevertError] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selId, setSelId] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [revertQueryInput, setRevertQueryInput] = useState("");
  const [deleteCustomerSupportById] = useLazyDeleteCustomerSupportByIdQuery();

  const [CustomerSupportList] = useLazyGetAllCustomerQuery();
  const [rows, setRows] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [openWarn, setOpenWarn] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const fetchData = async (page: number, search: string) => {
    setLoading(true);
    try {
      const response = await CustomerSupportList({
        value,
        page,
        search,
      }).unwrap();
      setLoading(false);
      if (response.statusCode === 200) {
        setRows(response?.data?.query);
        setTotalCount(response?.data?.totalQuery);
      } else {
        setRows([]);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleRevertQuery = async () => {
    setLoading(true);
    try {
      let body = {
        revertQuery: revertQueryInput,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await revertQuery({
        body: encryptedBody,
        id: selId,
      }).unwrap();
      setLoading(false);
      if (response.statusCode === 200) {
        console.log("gdywugdw", body);
        successToast("Query reverted successfully");
        fetchData(page, searchTerm);
        setOpen(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  const table = (
    <TableContainer className="table_container">
      <Box className="heading"></Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">S.No</TableCell>
            <TableCell>User Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Queries</TableCell>
            <TableCell>Date & Time</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, i: any) => (
            <TableRow>
              <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
              <TableCell>
                {row?.parents?.[0]?.name || row?.tutors?.[0]?.name}
              </TableCell>

              <TableCell>
                {row?.parents?.[0]?.email || row?.tutors?.[0]?.email}
              </TableCell>
              <TableCell>
                {row?.parents.length > 0 ? "Parents" : "Tutor"}
              </TableCell>

              <TableCell>{row?.query}</TableCell>
              <TableCell>{new Date(row?.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Box className="table_actions">
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setSelId(row?._id);
                    }}
                    id="abvd2"
                  >
                    <ReplyIcon />
                  </IconButton>

                  <IconButton
                    id="abvd"
                    onClick={() => {
                      setSelectedId(row?._id);
                      setOpenWarn(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [value, page, debouncedSearchTerm]);

  const handleDeeletequery = async (userId: any) => {
    try {
      const response = await deleteCustomerSupportById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Query deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Customer Support</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Queries Received" {...a11yProps(0)} />
              <Tab label="Complaints/Disputes" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              {table}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header"></Box>
              {table}
            </CustomTabPanel>
          </Box>
        </Card>
      </div>

      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogTitle>Revert Query</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="revert-query-input"
            label="Revert Query"
            type="text"
            fullWidth
            sx={{ border: "aliceblue" }}
            // value={revertQueryInput}
            onChange={(e) => {
              const inputValue = e.target.value;
              console.log("inp", inputValue);
              if (inputValue.length <= 200) {
                setRevertQueryInput(inputValue);
                setRevertError(false);
              } else {
                setRevertError(true);
              }
            }}
            error={revertError}
            helperText={
              revertError ? "Revert query can't be more than 200 words." : ""
            }
          />
        </DialogContent>

        <DialogActions>
          <Button sx={{ background: "#65a442" }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={{ background: "#65a442" }}
            onClick={handleRevertQuery}
            disabled={revertError || revertQueryInput.trim().length === 0}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={openWarn}
        setOpen={setOpenWarn}
        handleDelete={() => handleDeeletequery(selectedId)}
        name="query"
      />
    </MainContainer>
  );
};

export default CustomerSupport;
