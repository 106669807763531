import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string;
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser = {
  page: number;
  search: string;
};
type bookingRefund = {
  encryptedBody?: CommonBody;

  id: string | undefined;
};
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // postLogIn: builder.mutation<
    //   CommonResponseType & { data: User },
    //   CommonBody
    // >({
    //   query: (body) => ({
    //     url: END_POINTS.login,
    //     method: "POST",
    //     body,
    //   }),
    // }),

    getAllBookings: builder.query<
      CommonResponseType & { data: any },
      { page?: number; search?: string; bookingType?: any }
    >({
      query: ({ page, search, bookingType }) => {
        let url = END_POINTS.booking;
        const queryParams = [];

        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }
        if (bookingType) {
          queryParams.push(`bookingType=${bookingType}`);
        }

        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getSpecificBookingById: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.booking}/${userId}/`,
        method: "GET",
      }),
    }),

    bookingCsv: builder.query<CommonResponseType & { data: any }, {}>({
      query: ({}) => ({
        url: `${END_POINTS.export}`,
        method: "GET",
      }),
    }),
    getRefundList: builder.query<
      CommonResponseType & { data: any },
      { page?: number; search?: string }
    >({
      query: ({ page, search }) => {
        let url = `${END_POINTS.refundList}`;
        url += `?isRefunded=true`;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }
        if (queryParams.length > 0) {
          url += `&${queryParams.join("&")}`;
        }
        return {
          url,
          method: "GET",
        };
      },
    }),
    getRefundCompletedList: builder.query<
      CommonResponseType & { data: any },
      { page?: number; search?: string }
    >({
      query: ({ page, search }) => {
        let url = `${END_POINTS.refundList}`;
        url += `?isRefunded=false`;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }
        if (queryParams.length > 0) {
          url += `&${queryParams.join("&")}`;
        }
        return {
          url,
          method: "GET",
        };
      },
    }),
    EditUser: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.updateParent}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteByIduser: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.deleteParent}/${userId}/`,
        method: "DELETE",
      }),
    }),

    postRefundBookingAmount: builder.mutation<
      CommonResponseType,
      bookingRefund
    >({
      query: ({ id, encryptedBody }) => {
        let url = `${END_POINTS.refundBookingAmount}/${id}`;

        return {
          url,
          method: "PUT",
          body: encryptedBody,
        };
      },
    }),

    getAllPayments: builder.query<
      CommonResponseType & { data: any },
      { page?: number; search?: string; bookingType?: any }
    >({
      query: ({ page, search, bookingType }) => {
        let url = END_POINTS.booking;
        const queryParams = [];

        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }

        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    GetInvoiceById: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.invoice}/${userId}/`,
        method: "GET",
      }),
    }),
    GetPaymentByIduser: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.booking}/${userId}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllBookingsQuery,
  useLazyGetSpecificBookingByIdQuery,
  useEditUserMutation,
  useLazyDeleteByIduserQuery,
  useLazyBookingCsvQuery,
  useLazyGetAllPaymentsQuery,
  useLazyGetPaymentByIduserQuery,
  useLazyGetInvoiceByIdQuery,
  usePostRefundBookingAmountMutation,
  useLazyGetRefundListQuery,
  useLazyGetRefundCompletedListQuery,
} = authApi;
