import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLazyGetGraphUserGraphQuery,
  useLazyGetEarningQuery,
  useLazyGetGraphTutorGraphQuery,
  useLazyGetTheBookingGraphQuery,
  useLazyGetDashboArdApiQuery,
  useLazyExportUserCsvQuery,
  useLazyExportTutorCsvQuery,
  useLazyExportBookingCsvQuery,
  useLazyExportRevenueCsvQuery} from "../../services/dashboard";
import { errorToast, successToast } from "../../helpers";
   
const Analytics = () => {
  const[data,setData]=useState<any>();
  const [selectedType1, setSelectedType1] = useState<string>("weekly"); 
  const [selectedType2, setSelectedType2] = useState<string>("weekly");
  const [selectedType3, setSelectedType3] = useState<string>("weekly");
  const [selectedType4, setSelectedType4] = useState<string>("weekly");   
  const [earningGraph, setEarningGraph] = useState<any>([]);
  const[bookingGraph,setbookingGraph] = useState<any>([]);
  const[tutorGraph,settutorGraph]=useState<any>([]);
  const[user,serUser]=useState<any>([]);
  const [EarningGraph] = useLazyGetEarningQuery();
  const[Usergraph]=useLazyGetGraphUserGraphQuery();
  const[TutorGraph]=useLazyGetGraphTutorGraphQuery();
  const[BookingsGraph]=useLazyGetTheBookingGraphQuery();
 const[exportUserCsv]=useLazyExportUserCsvQuery();
 const[exportTutorCsv]=useLazyExportTutorCsvQuery();
 const[exportBookingCsv]=useLazyExportBookingCsvQuery();
 const[exportRevenue]=useLazyExportRevenueCsvQuery();
 const handleExportUserCsv = async () => {
  try {
    const res = await exportUserCsv({ type: selectedType1, query: "csv" }).unwrap();
    console.log("eugded", res);
  
    const parsedRes = typeof res === "string" ? JSON.parse(res) : res;
  
    if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
      window.open(parsedRes.message.s3Location);
      successToast("CSV exported successfully")
    } else {
      errorToast("Error: s3Location not found in response");
    }
  } catch (error: any) {
    errorToast(error?.message);
  }
};
const handleExportTutorCsv = async () => {
  try {
    const res = await exportTutorCsv({ type: selectedType2, query: "csv" }).unwrap();
    console.log("eugded", res);
  
    const parsedRes = typeof res === "string" ? JSON.parse(res) : res;
  
    if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
      window.open(parsedRes.message.s3Location);
      successToast("CSV exported successfully")
    } else {
      errorToast("Error: s3Location not found in response");
    }
  } catch (error: any) {
    errorToast(error?.message);
  }
};
const handleExportBookingCsv = async () => {
  try {
    const res = await exportBookingCsv({ type: selectedType3, query: "csv" }).unwrap();
    console.log("eugded", res);
  
    const parsedRes = typeof res === "string" ? JSON.parse(res) : res;
  
    if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
      window.open(parsedRes.message.s3Location);
      successToast("CSV exported successfully")
    } else {
      errorToast("Error: s3Location not found in response");
    }
  } catch (error: any) {
    errorToast(error?.message);
  }
};
const handleExportRevenueCsv = async () => {
  try {
    const res = await exportRevenue({ type: selectedType4, query: "csv" }).unwrap();
    console.log("eugded", res);
  
    const parsedRes = typeof res === "string" ? JSON.parse(res) : res;
  
    if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
      window.open(parsedRes.message.s3Location);
      successToast("CSV exported successfully")
    } else {
      errorToast("Error: s3Location not found in response");
    }
  } catch (error: any) {
    errorToast(error?.message);
  }
};

  const fetchEarningGraph = async (type: string) => {
    try {
      const response = await EarningGraph(type).unwrap();
      console.log("res---Earning analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name, 
        value: item.value,
      }));
      setEarningGraph(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchBookingGraph = async (type: string) => {
    try {
      const response = await BookingsGraph(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name, 
        value: item.value,
      }));
      setbookingGraph(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchUsersGraph = async (type: string) => {
    try {
      const response = await Usergraph(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name, 
        value: item.value,
      }));
      serUser(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchTutorsGraph = async (type: string) => {
    try {
      const response = await TutorGraph(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name, 
        value: item.value,
      }));
      settutorGraph(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType1(newType);  
  }; 
  const handleChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType2(newType); 
  };
  const handleChange3 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType3(newType);  
  };
  const handleChange4 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType4(newType);  
  };
  useEffect(() => {
    
    fetchEarningGraph(selectedType4);
  }, [selectedType4]);
  useEffect(() => {
     
    fetchBookingGraph(selectedType3);
  }, [selectedType3]);
  useEffect(() => {
     
    fetchUsersGraph(selectedType1);
  }, [selectedType1]);
  useEffect(() => {
     
    fetchTutorsGraph(selectedType2);
  }, [selectedType2]);
  const graphRevenueData = {
    labels: bookingGraph?.map((item: any) => item.label), 
    datasets: [
      {
        label: "Total Bookings",
        data: bookingGraph?.map((item: any) => item.value),  
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };

  const graphUsersData = {
    labels: user?.map((item: any) => item.label), 
    datasets: [
      {
        label: "Total Users",
        data: user?.map((item: any) => item.value),  
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };

  const graphAdvertisersData = {
    labels: tutorGraph?.map((item: any) => item.label), 
    datasets: [
      {
        label: "Total Tutors",
        data: tutorGraph?.map((item: any) => item.value),  
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };

  const graphTransactionsData = {
    labels: earningGraph?.map((item: any) => item.label), 
    datasets: [
      {
        label: "Total Revenue",
        data: earningGraph?.map((item: any) => item.value),  
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">Reports and Analytics</h1>
            <Box className="cards_header_right">
              
            </Box>
          </div>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              Total Users
                <FormControl>
                  <div> 
                <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>

                <NativeSelect
        defaultValue={selectedType1}
        onChange={handleChange1}
        inputProps={{
          name: "report",
          id: "uncontrolled-native",
        }}
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
      </NativeSelect>
      </div>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              Total Tutors
                <FormControl>
                  <div> 
                <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportTutorCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
                <NativeSelect
        defaultValue={selectedType2}
        onChange={handleChange2}
        inputProps={{
          name: "report",
          id: "uncontrolled-native",
        }}
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
      </NativeSelect>
      </div>
                </FormControl>
              </h2>
              <LineChart data={graphAdvertisersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              Total Bookings
                <FormControl>
                  <div> 
                <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportBookingCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
                <NativeSelect
        defaultValue={selectedType3}
        onChange={handleChange3}
        inputProps={{
          name: "report",
          id: "uncontrolled-native",
        }}
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
      </NativeSelect>
      </div>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              Total Revenue
                <FormControl>
                  <div> 
                <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportRevenueCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
                <NativeSelect
        defaultValue={selectedType4}
        onChange={handleChange4}
        inputProps={{
          name: "report",
          id: "uncontrolled-native",
        }}
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
      </NativeSelect>
      </div>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionsData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
