import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UploadMedia } from "../../../utils/uploadMedia";
import { errorToast, successToast } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { useEditUserMutation, useLazyGetSpecificUserByIdQuery } from "../../../services/users";
import SearchBar from "../../../components/SearchBar";
import { isValidInput } from "../../../utils/validation";
import Loader from "../../../helpers/Loader";
const UsersForm = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);

  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [dialCode, setPhoneCode] = useState("+91");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [profile_picture, setProfilePicture] = useState("");
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  console.log("idhhdi", ByIdDetails);
  const [editUSERbyId] = useEditUserMutation();
  console.log("d3d", ByIdDetails);
  console.log("profile_picture", profile_picture);
  const [imageId, setImageId] = useState(null);
  const navigate = useNavigate();

  const [fetchApi,{isLoading}] = useLazyGetSpecificUserByIdQuery();
  const { id } = useParams();
 
  console.log("iwhdid",id);

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(null);
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        formik.setFieldValue("profile_picture", res?.data?.image);
        setProfilePicture(res?.data?.image);
        successToast("image uploaded successfully");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      console.log("909220", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);

  const handleChangePhone = (phoneNo: any, country: any) => {
    formik.setFieldValue(
      "phoneNo",
      phoneNo.replace(country.country_code, "")
    );
    setPhoneCode(`+${country.country_code}`); // Update the dialCode state with the correct format
  };

  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
    
      email: ByIdDetails?.email || "",
      phoneNo: ByIdDetails?.phoneNo || "",
      profile_picture:ByIdDetails?.image|| profile_picture,
      dialCode: dialCode,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
       
      name: Yup.string()
        .required("Full name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      phoneNo: Yup.string()
        .required("Phone number is required")
        .min(5, "Phone number must be at least 5 characters")
        .max(16, "Phone number must be at most 16 characters"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);
      let body = {
        name:values.name||"",
        email: values.email || "",
        phoneNo: values.phoneNo || "",
        image: values?.profile_picture || "",
        dialCode: dialCode, // Include the dialCode here
      };
      console.log("u2hs2", body);
      
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        const response = await editUSERbyId({ id, body: encryptedBody }).unwrap();

        

        if (response?.statusCode === 200) {
          console.log("---tutor", body);
          successToast("User updated successfully");
        }
      } catch (error: any) {
        console.log(error);
        errorToast(error?.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        // image:ByIdDetails?.image||"",
        name: ByIdDetails?.name || "",
        // lastName: ByIdDetails?.lastName || "",
        email: ByIdDetails?.email || "",
        phoneNo: ByIdDetails?.phoneNo || "",
        profile_picture: ByIdDetails?.image || "",
        dialCode: ByIdDetails?.dialCode || "",
      });
    }
  }, [ByIdDetails]);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            
            <h1 className="mn_hdng"> Edit User Profile</h1>
            <Loader isLoad={isLoading} />
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manageUsers");
              }}
            >
              Back
            </Button>
          </div>
       
          <Card className="cards">
         
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {formik.values.profile_picture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.profile_picture}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={(e) => {
                            e.preventDefault();
                            setProfilePicture("");
                            formik.setFieldValue("profile_picture", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button
                            component="span"
                            className="upload_image_btn"
                          >
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Full Name
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="Full Name"
                      variant="outlined"
                      fullWidth
                      placeholder="Full Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.name && formik.errors.name
                          ? formik.errors.name.toString()
                          : ""
                      }
                    ></TextField>
                  </Grid>
                 
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email.toString()
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      value={formik.values?.dialCode+formik.values.phoneNo}
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phoneNo && formik.errors.phoneNo ? (
                      <h6 className="err_msg">
                        {formik.touched.phoneNo &&
                          formik.errors.phoneNo.toString()}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default UsersForm;
