import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import { useLazyGetTeachingDetailsByIdQuery, usePostTutorTeachingDetailsMutation } from "../../services/tutor";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, SubjectBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";
import moment from "moment";
import { usePutTeachingDetailsMutation } from "../../services/tutor";
import DeleteIcon from "@mui/icons-material/Delete";
import Multiselect from "multiselect-react-dropdown";
import { useLazyGetIdProofByIdQuery } from "../../services/documentation";
import { isString } from "../../utils/validation";
import Loader from "../../helpers/Loader";
interface Class {
  class: string;
}

interface Subject {
  subject: string;
}



function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TeachingDetails = () => {
  const location = useLocation();
  const [detailsById, { isLoading }] = useLazyGetTeachingDetailsByIdQuery();
  const { state } = location;
  const navigate = useNavigate();
  const [curriculam, setCurriculam] = useState<any>([]);

  const [classNumber, setClassNumbers] = useState<string[]>([])
  ////  console.log(classes, "CLASSES")
  const [error, setError] = useState(false);

  const [classList, setclassList] = useState<Class[]>([{ class: "" }]);


  const [error1, setError1] = useState<boolean>(false);
  ////  console.log(error1, "error1");

  const [error2, setError2] = useState<boolean>(false);
  const [error3, setError3] = useState<boolean>(false);
  const [error4, setError4] = useState<boolean>(false);
  const [subject, setSubject] = useState<SubjectBody[]>([]);
  const [name, setName] = useState<string>("");
  const [responseClasses, setResponseClasses] = useState<any[]>([]);
  const [selectedCurriculam, setSelectedCurriculam] = useState<any>("");
  const [selectedTeaching, setselectedTeaching] = useState<any>("");
  const { _id } = useParams();
  const [experience, setExperience] = useState<string>("");
  const [AddTeachingDetails] = usePostTutorTeachingDetailsMutation();
  const [teachingStyle, setTeachingStyle] = useState<any>([]);
  const [ByiDdetails, setByIdDetails] = useState<any>([]);
  const [tutorId, setTutorId] = useState<string | null>(null);
  const [prevClasses, setPrevClasses] = useState<any>([]);
  const [prevSubjects, setPrevSubjects] = useState<any>([]);
  const [newCurriculum, setNewCurriculum] = useState<string>("");
  const [showNewCurriculumInput, setShowNewCurriculumInput] = useState<boolean>(false);
  const [otherCurriculum, setOtherCurriculum] = useState('');
  const [C1, setC1] = useState<number[]>([]);
  console.log("C11", C1);

  let find5 = false;
  if (C1 && C1.length >= 1) {
    for (let i = 0; i < C1.length; i++) {
      if (C1[i] === 5) {
        find5 = true;
        break;
      }
    }
  }

  ////  console.log("gcuegge", tutorId);
  // const handleRemoveSubject = (indexRemove: any) => {
  //   const updatedList = subjectList.filter((_, index) => index !== indexRemove);
  ////  //   console.log(indexRemove);
  //   setSubjectList(updatedList);
  // };
  const handleDelete = (index: number) => {
    const updatedSubjectList = [...subject];
    updatedSubjectList.splice(index, 1);
    setSubject(updatedSubjectList);
  };

  const onSelectedClassChange = (event: any) => {
    const { value } = event.target;
    setClassNumbers(value);
  };

  const onExpClassChange = (event: any) => {
    const { value } = event.target;
    setExperience(value);
  };

  console.log(classNumber, "classsssssss");


  enum CurriculumType {
    Cambridge = 1,
    IB = 2,
    National = 3,
    American = 4,
    Others = 5
  }
  const curriculumOptions = [
    { value: CurriculumType.Cambridge, label: "Cambridge Curriculum" },
    { value: CurriculumType.IB, label: "IB Curriculum" },
    { value: CurriculumType.National, label: "National Curriculum" },
    { value: CurriculumType.American, label: "American Curriculum" },
    { value: CurriculumType.Others, label: "Others" }
  ];
  enum TeachingStyle {
    VisualLearning = 1,
    AuditoryLearning = 2,
    ReadingAndWriting = 3,
    IntegratedApproach = 4,
    Others = 5,
  }


  const classOptions = [
    { label: "Pre primary(Kg/Foundation)", value: "0" },
    { label: "Primary", value: "1" },
    { label: "Middle school (O-level)", value: "2" },
    { label: "High school (A-level)", value: "3" },
    { label: "College", value: "4" },
    { label: "Other", value: "5" }
  ]



  const teachingStyleOptions = [
    { value: TeachingStyle.VisualLearning, label: "Visual Learning" },
    { value: TeachingStyle.AuditoryLearning, label: "Auditory Learning" },
    { value: TeachingStyle.ReadingAndWriting, label: "Reading and Writing" },
    { value: TeachingStyle.IntegratedApproach, label: "Integrated Approach" },
    { value: TeachingStyle.Others, label: "Others" },
  ];

  const handleTeachingStyleChange = (event: any) => {
    const selectedTeaching = event.target.value as string[];
    setTeachingStyle(event?.target?.value);
  };

  const fetchDataById = async () => {
    try {
      const response = await detailsById({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        const teachingDetails = response.data[0];
        setC1(response?.data[0]?.curriculum);
        setOtherCurriculum(response?.data[0]?.curriculumOther);
        setPrevClasses(response?.data[0]?.classes || []);
        setPrevSubjects(response?.data[0]?.subjects || []);
        setClassNumbers(response?.data[0]?.classes?.map((item: any) => item.name) || []);
        setTeachingStyle(response?.data[0]?.teachingStyle || []);
        setCurriculam(response?.data[0]?.curriculum || []);
        setselectedTeaching(response?.data[0]?.teachingStyle || []);
        setResponseClasses(response?.data[0]?.classes || []);
        setExperience(response?.data[0]?.totalTeachingExperience || "")
        const subjectsData = response?.data[0]?.subjects || [];
        setSubject(subjectsData);
        formik.setFieldValue(
          "totalTeachingExperience",
          teachingDetails?.totalTeachingExperience
        );
        formik.setFieldValue(
          "startTime",
          teachingDetails.startTime.substring(11, 16)
        );
        formik.setFieldValue(
          "endTime",
          teachingDetails.endTime.substring(11, 16)
        );
        formik.setFieldValue("price", teachingDetails.price);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById();
    }
  }, [_id]);

  const handleCurriculamChange = (event: SelectChangeEvent<string[]>) => {
    const selectedCurriculam = event.target.value as string[];
    const numericCurriculam: number[] = selectedCurriculam.map((curriculum) => Number(curriculum));

    setCurriculam(numericCurriculam);
    setSelectedCurriculam(numericCurriculam.join(", "));


    if (numericCurriculam.includes(CurriculumType.Others)) {
      setShowNewCurriculumInput(false);
    } else {
      setShowNewCurriculumInput(true);
      setNewCurriculum("");
    }
  };


  const getLevelName = (item: string) => {
    switch (item) {
      case "0":
        return 'Pre primary(Kg/Foundation)';
      case "1":
        return 'Primary';
      case "2":
        return 'Middle school (O-level)';
      case "3":
        return 'High school (A-level)';
      case "4":
        return 'College';
      case "5":
        return 'Other';
      default:
        return '';
    }
  }


  const TeachingDetailsSchema = Yup.object().shape({
    price: Yup.number()
      .max(10000000, "Price cannot be more than 10000000")
      .required("Price is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
  });




  const formik = useFormik({
    initialValues: {
      tutorId: _id ? _id : tutorId,
      price: "",
      startTime: "",
      endTime: "",
    },
    validationSchema: TeachingDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // if (!formik.isValid) return;
      setError1(false);
      setError2(false);
      setError3(false);
      setError4(false);

      if (curriculam?.length < 1) {
        setError2(true);
        return;
      }

      if (classNumber?.length < 1) {
        setError1(true);
        return;
      }

      if (teachingStyle?.length < 1) {
        setError3(true);
        return;
      }
      if (experience?.length < 1) {
        setError4(true);
        return;
      }
      if (subject?.length === 0) {
        errorToast("Enter atleast one subject")
        return;
      }
      if (curriculam.includes(CurriculumType.Others) && otherCurriculum === "") {
        errorToast("Specify Other Curriculum")
      }
      const tempClass = responseClasses.map((item) => {
        if (classNumber.find((data: any) => data == item.name)) {
          return { ...item, isDelete: false };
        }
        else {
          return { ...item, isDelete: true }
        }
      });
      classNumber.map((item: any) => {
        if (!responseClasses.find(data => data.name == item)) {
          tempClass.push({ name: item });
        }
      })
      const tempSubject = prevSubjects.map((item: any) => {
        if (subject.find((data: any) => data.name == item.name)) {
          return { ...item, isDelete: false };
        }
        else {
          return { ...item, isDelete: true }
        }
      });
      subject.map((item: any) => {
        if (!prevSubjects.find((data: any) => data.name == item.name)) {
          tempSubject.push(item);
        }
      })
      console.log(tempSubject);
      // console.log(tempClass);

      setSubmitting(true);

      const startTimeUtc = values.startTime
        ? moment.utc(values.startTime, "HH:mm").format()
        : "";
      const endTimeUtc = values.endTime
        ? moment.utc(values.endTime, "HH:mm").format()
        : "";
      const curriculumOther = curriculam.includes(CurriculumType.Others)
        ? otherCurriculum
        : null;

      const curriculum = curriculam;
      let body: any = {
        tutorId: _id ? _id : localStorage.getItem("tutorId"),
        totalTeachingExperience: Number(experience) || "",
        curriculum: curriculum,
        ...(curriculum.includes(CurriculumType.Others) && { curriculumOther: otherCurriculum }),
        classes: tempClass,
        teachingStyle: teachingStyle || "",
        price: values.price || "",
        startTime: startTimeUtc,
        endTime: endTimeUtc,
        subject: tempSubject
      };


      console.log("b1b1", body);
      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        let response = await AddTeachingDetails(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (!_id) {
            successToast("Teaching details added successfully");
          } else {
            successToast("Teaching details updated successfully");
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
      // }
    },

  });

  const handleAdd = () => {
    if (name.trim() === '') {
      console.error('Subject name cannot be empty');
      return;
    }
    ////    console.log('Adding subject:', name);
    setSubject((prev) => [...prev, { name: name }]);
    setName('');
  };
  // const handleDelete=(index:number)=>{
  //   const newArr=[...subject]
  //   newArr.splice(index,1)
  //   setSubject(newArr)
  // }
  useEffect(() => {
    const storedTutorId = localStorage.getItem("tutorId");
    if (storedTutorId) {
      setTutorId(storedTutorId);
    }
  }, [_id]);

  return (
    <div className="main_loyout">
      <div className="dashboard">
        {state?.type === "edit" ? (
          <h1 className="mn_hdng">Edit Tutor</h1>
        ) : (
          <h1 className="mn_hdng">Teaching Deatils</h1>
        )}
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-tutors");
          }}
        >
          Back
        </Button>
      </div>
      <Loader isLoad={isLoading} />
      <Card className="cards">
        <form onSubmit={formik.handleSubmit}>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">
                    Total Teaching Experience
                  </Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    value={experience}
                    onChange={onExpClassChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1" > 1 year</MenuItem>
                    <MenuItem value="2" > 2 years</MenuItem>
                    <MenuItem value="3" > 3 years</MenuItem>
                    <MenuItem value="4" > 4 years</MenuItem>
                    <MenuItem value="5" > 5+ years </MenuItem>

                  </Select>
                  {error4 && experience.length === 0 ? (
                    <h6 className="err_msg">
                      Please select Experience
                    </h6>
                  ) : (
                    null
                  )}
                </FormControl>
                {/* <TextField
                  hiddenLabel
                  type={"text"}
                  name="totalTeachingExperience"
                  variant="outlined"
                  fullWidth
                  placeholder="Total Teaching Experience"
                  id="totalTeachingExperience"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalTeachingExperience}
                  helperText={
                    formik.touched.totalTeachingExperience &&
                      formik.errors.totalTeachingExperience
                      ? String(formik.errors.totalTeachingExperience)
                      : undefined
                  }
                /> */}
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">Select Class</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    multiple
                    value={classNumber}
                    onChange={onSelectedClassChange}
                    displayEmpty
                    renderValue={(selected) => {

                      if (selected?.length == 0)
                        return <span>Select</span>
                      return selected.map(getLevelName).join(', ');
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {classOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}> {/* Convert value to string */}
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {error1 && classNumber.length === 0 ? (
                    <h6 className="err_msg">
                      Please select class
                    </h6>
                  ) : (
                    null
                  )}
                </FormControl>
              </Grid>


              {/* class
 

{/* class */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">Select Curriculum</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    multiple
                    value={curriculam ? curriculam.map(String) : []}
                    onChange={handleCurriculamChange}
                    displayEmpty
                    renderValue={(selected: string[]) => {
                      if (selected.length == 0)
                        return <span>Select</span>;

                      return selected.map(value =>
                        curriculumOptions.find(option => option.value === Number(value))?.label || ""
                      ).join(", ");
                    }}
                  >
                    <MenuItem value="" disabled>Select</MenuItem>
                    {curriculumOptions.map(option => (
                      <MenuItem key={option.value} value={String(option.value)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {error2 && !curriculam.length ? (
                    <h6 className="err_msg">Please select curriculum</h6>
                  ) : null}
                </FormControl>

              </Grid>

              {curriculam.includes(CurriculumType.Others) && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">  Other Curriculum</Typography>
                  <TextField

                    type="text"
                    name="other-curriculum"
                    variant="outlined"
                    fullWidth
                    placeholder="Specify Other Curriculum"
                    id="other-curriculum"
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={otherCurriculum}
                    onChange={(e) => setOtherCurriculum(e.target.value)}

                  />
                </Grid>
              )}




              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">
                    Select Teaching Style
                  </Typography>
                  {/* <InputLabel id="teaching-style-label">Select</InputLabel> */}
                  <Select
                    className="select_div"
                    labelId="teaching-style-label"
                    id="demo-simple-select"
                    displayEmpty
                    multiple
                    value={teachingStyle ? teachingStyle?.map(String) : []}
                    onChange={handleTeachingStyleChange}
                    renderValue={(selected: string[]) => {
                      if (selected.length == 0)
                        return <span>Select</span>
                      return selected?.map(
                        (value) =>
                          teachingStyleOptions.find(
                            (option) => option.value === Number(value)
                          )?.label || ""
                      )
                        .join(", ")
                    }
                    }
                  >
                    <MenuItem value="" disabled>
                      Select{""}
                    </MenuItem>
                    {teachingStyleOptions?.map((option) => (
                      <MenuItem key={option.value} value={String(option.value)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>

                  {error3 && !teachingStyle.length ? (
                    <h6 className="err_msg">Please select teaching style</h6>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Price</Typography>
                <TextField
                  hiddenLabel
                  type="text"
                  name="price"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="price"
                  id="price"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.price}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Start Time</Typography>
                <TextField
                  hiddenLabel
                  type={"time"}
                  name="startTime"
                  variant="outlined"
                  fullWidth
                  placeholder="start time"
                  id="startTime"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.startTime}
                  // value={ByIdDetails?.startTime?ByIdDetails?.startTime:formik.values.startTime}
                  helperText={
                    formik.touched.startTime && formik.errors.startTime
                      ? formik.errors.startTime.toString()
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">End Time</Typography>
                <TextField
                  hiddenLabel
                  type={"time"}
                  name="endTime"
                  variant="outlined"
                  fullWidth
                  placeholder="end time"
                  id="endTime"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.endTime}
                  // value={ formik.values.endTime}
                  helperText={
                    formik.touched.endTime && formik.errors.endTime
                      ? formik.errors.endTime.toString()
                      : ""
                  }
                />
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Subjects</Typography>
                <TextField
                  hiddenLabel
                  name="subject"
                  variant="outlined"
                  fullWidth
                  value={name}
                  placeholder="Enter Subject"
                  id="subject"
                  className="text_field"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                      return;
                    } else if (isString(val.target.value)) {
                      setName(val.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Button
                  size="large"
                  sx={{
                    marginTop: "20px",
                  }}
                  className="btn btn_primary"
                  onClick={handleAdd}

                >
                  Add subject
                </Button>
              </Grid>

              {subject?.length ? (
                <>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      Subjects Name
                    </Typography>
                  </Grid>
                  {subject?.map((item, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="Subject"
                        variant="outlined"
                        fullWidth
                        value={item?.name}
                        disabled
                        placeholder="Enter Subject"
                        id="date"
                        className="text_field"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className="cross_btn">
                              <IconButton
                                onClick={() => {
                                  handleDelete(index)
                                }}

                              >

                                <DeleteIcon sx={{ padding: "2px", background: "white" }} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  ))}
                </>
              ) : null}

              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Enter subjects</Typography>
                <TextField
                  hiddenLabel
                  type="text"
                  name="subjects"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter subjects"
                  className="text_field"
                  onChange={(e) => setSubValue(e.target.value)}
                  onBlur={formik.handleBlur}
                  value={subValue}
                />
              </Grid> 
               <Grid
                item
                lg={8}
                md={8}
                sm={8}
                style={{ paddingTop: "8px" }}
              >
                <div className="form_btn">
                  <Button
                    size="large"
                    type="button"
                    className="btn btn_primary"
               
                    onClick={handleAddSubjects}
                  >
                    Add
                  </Button>
                </div>
              </Grid> 

               <Grid xs={12} className="subWrap" >
              {
              subjectList?.length > 1 ?
              subjectList?.slice(1,subjectList?.length)?.map((item, index) => (
                  <div className="subjects">
                 <CancelIcon className="cancelIcon" onClick={() => handleRemoveSubject(index+1)} />
                 <h3>{item.subject}</h3>
                 </div>
              )):""}
              </Grid>               

              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Class</Typography>
                <TextField
                  hiddenLabel
                  type="text"
                  name="class"
                  variant="outlined"
                  fullWidth
                  placeholder="class"
                  className="text_field"
                //   onChange={formik.handleChange}
                  onChange={(e) => setClassValue(e.target.value)}
                  onBlur={formik.handleBlur}
                  value={classValue}
                />
              </Grid> */}

              {/* <Grid
                item
                lg={8}
                md={8}
                sm={8}
                style={{ paddingTop: "8px" }}
              >
                <div className="form_btn">
                  <Button
                    size="large"
                    type="button"
                    className="btn btn_primary"
                    onClick={handleAddClass}
                  >
                    Add
                  </Button>
                </div>
              </Grid> */}

              <Grid xs={12} className="classWrap">
                {classList?.length > 1
                  ? classList
                    ?.slice(1, classList?.length)
                    ?.map((item, index) => (
                      <div className="class">
                        <CancelIcon
                          className="cancelIcon"
                        // onClick={() => handleRemoveClass(index + 1)}
                        />
                        <h3>{item.class}</h3>
                      </div>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <div className="form_btn">
              <Button
                size="large"
                type="submit"
                className="btn btn_primary"
                onClick={() => {
                  setError(true);
                  setError1(true);
                  setError2(true);
                  setError3(true);
                  setError4(true)
                }}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};



export default TeachingDetails;