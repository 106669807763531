import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tabs,
  Tab,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNumber } from "../../utils/validation";
import {
  useAddTutorInfoMutation,
  useLazyGetutorByIdQuery,
  usePutTutorProfileMutation,
} from "../../services/tutor";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../helpers";
import { UploadMedia } from "../../utils/uploadMedia";
import Loader from "../../helpers/Loader";
interface Class {
  class: string;
}

interface Subject {
  subject: string;
}

interface IdProof {
  idProof: string;
}

const ProfileSetup = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [frontDocImage, setFrontDocImage] = useState<string>("");
  const [backDocImage, setBackDocImage] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const { _id } = useParams();
  const [dialCode, setPhoneCode] = useState("+91");

  const [profile_picture, setProfilePicture] = useState<any>("");
  const [ByIdDetails, setByIdDetails] = useState<any>([]);

  console.log("d3d", ByIdDetails);
  console.log("profile_picture", profile_picture);
  const [imageId, setImageId] = useState(null);
  const [startTime, setStartTime] = useState("");
  console.log("iwhfiheif", startTime);
  const [endTime, setEndTime] = useState("");
  const [addTutorInfo, { isLoading }] = useAddTutorInfoMutation();
  const [updateTutor] = usePutTutorProfileMutation();
  const [getTutorById] = useLazyGetutorByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await getTutorById({ userId: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
        setSelectedGender(response?.data?.gender);
        setSelectTeachingOption(response?.data?.teachingOption);
        setEducationLevelChange(
          response?.data?.teachingdetails?.educationLevel
        );
        setSelectTeachingOption(
          response?.data?.teachingdetails?.teachingOption
        );
        setPhoneCode(ByIdDetails?.dialCode ? ByIdDetails?.dialCode : dialCode);
        formik.setFieldValue("dialCode", response?.data?.dialCode);
        formik.setFieldValue("profile_picture", response?.data?.image);
        formik.setFieldValue("email", response?.data?.email);
        formik.setFieldValue("userName", response?.data?.userName);
        formik.setFieldValue("phoneNo", response?.data?.phoneNo);
        formik.setFieldValue("gender", response?.data?.gender);
        formik.setFieldValue("age", formatDate(response?.data?.age));
        formik.setFieldValue("shortBio", response?.data?.shortBio);
        formik.setFieldValue("name", response?.data?.name);
        formik.setFieldValue("address", response?.data?.address);
        formik.setFieldValue(
          "startDate",
          response?.data?.teachingDetails?.startDate
        );
        formik.setFieldValue(
          "endDate",
          response?.data?.teachingDetails?.endDate
        );
        formik.setFieldValue(
          "totalTeachingExperience",
          response?.data?.teachingdetails?.totalTeachingExperience
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(null);
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        formik.setFieldValue("profile_picture", res?.data?.image);
        setProfilePicture(res?.data?.image);
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  // for the idProof
  const [idProofImage, setidProofImage] = useState<string>("");
  //   const handleIdProof = () => {
  //   const newFields: IdProof[] = [...idProofImage, {idProof: ""}];
  //   setidProofImage(newFields);
  // }

  const [selectedGender, setSelectedGender] = useState("");
  const [EducationLevelChange, setEducationLevelChange] = useState("");
  const [SelectTeachingOption, setSelectTeachingOption] = useState("");

  const [subjectList, setSubjectList] = useState<Subject[]>([{ subject: "" }]);

  const [error, setError] = useState(false);

  const [classList, setclassList] = useState<Class[]>([{ class: "" }]);

  // for subject
  const handleSubjectField = () => {
    const newFields: Subject[] = [...subjectList, { subject: "" }];
    setSubjectList(newFields);
  };

  // for class
  const handleClassField = () => {
    const newFields: Class[] = [...classList, { class: "" }];
    setclassList(newFields);
  };

  const handleGenderChange = (event: any) => {
    setSelectedGender(event.target.value);
  };
  const handleEducationLevelChange = (event: any) => {
    setEducationLevelChange(event.target.value);
  };
  const handleTeachingOptionChange = (event: any) => {
    setSelectTeachingOption(event.target.value);
  };
  const formatDate = (isoString: string) => {
    if (!isoString) return "";
    return isoString.split("T")[0];
  };

  console.log("idgwd", ByIdDetails?.age);
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      image: ByIdDetails?.image || profile_picture,
      name: ByIdDetails?.name || "",
      userName: ByIdDetails?.userName || "",
      email: ByIdDetails?.email || "",
      phoneNo: ByIdDetails?.phoneNo || "",
      age: formatDate(ByIdDetails?.age) || "",
      totalTeachingExperience: ByIdDetails?.totalTeachingExperience || "",
      educationLevel: ByIdDetails?.educationLevel || "",
      teachingOption: ByIdDetails?.teachingOption || "",
      dialCode: ByIdDetails?.dialCode ? ByIdDetails?.dialCode : dialCode,
      gender: ByIdDetails?.gender || selectedGender,
      startTime: ByIdDetails?.startTime || "",
      endTime: ByIdDetails?.endTime || "",
      shortBio: ByIdDetails?.shortBio || "",
      address: ByIdDetails?.address || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Full Name is required"),
      address: Yup.string().required("Address is required"),
      email: Yup.string()
        .email("Enter a valid email address")
        .required("Email is required"),
      userName: Yup.string().required("User name is required"),
      phoneNo: Yup.string().required("Phone Number is required"),
      age: Yup.string().required("This field is required"),
      gender: Yup.string().required("Gender is required"),
      shortBio: Yup.string().required("Short Bio is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!formik.isValid) return;

        setSubmitting(true);

        console.log("Tutor Values:", values);

        const body = {
          email: values.email || "",
          name: values.name || "",
          dialCode: dialCode,
          phoneNo: values.phoneNo || "",
          image: profile_picture,
          age: String(values.age) || "",
          userName: values.userName,
          gender: selectedGender,
          shortBio: values.shortBio || "",
          address: values.address || "",
        };

        const body2 = {
          ids: [_id] || "",
          status: "tutorUpdate",
          email: values.email || "",
          name: values.name || "",
          dialCode: dialCode,
          phoneNo: values.phoneNo || "",
          image: profile_picture,
          age: String(values.age) || "",
          userName: values.userName,
          gender: selectedGender,
          shortBio: values.shortBio || "",
          address: values.address || "",
        };
        console.log("eudheudgued", body);
        if (!_id) {
          try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const response: any = await addTutorInfo(encryptedBody).unwrap();
            if (response?.statusCode === 200) {
              successToast("Tutor added successfully");
              localStorage.setItem("tutorId", response?.data?._id);
              setToStorage(STORAGE_KEYS.tutorId, response?.data?._id);
            }
          } catch (error: any) {
            console.error("Error adding tutor:", error);
            if (error?.response?.data?.message) {
              errorToast(error?.response?.data?.message);
            } else {
              errorToast(error?.data?.message || "");
            }
          }
        } else {
          try {
            console.log("---00000", body);
            const encryptedBody = generateEncryptedKeyBody(body2) as CommonBody;
            let response = await updateTutor({ encryptedBody }).unwrap();
            if (response?.statusCode === 200) {
              successToast("Tutor updated successfully");
            }
          } catch (e: any) {
            errorToast(e?.data?.message || "");
          }
        }
      } catch (error: any) {
        errorToast(error?.message);
      } finally {
        setSubmitting(false);
      }
    },
  });
  // for subjects
  const handleAddSubject = (index: number, value: string) => {
    const newFields = [...subjectList];
    newFields[index].subject = value;
    setSubjectList(newFields);
  };

  // for class
  const handleAddClass = (index: number, value: string) => {
    const newFields = [...classList];
    newFields[index].class = value;
    setclassList(newFields);
  };

  //   // for idproof
  //   const handleAddIdProof = (index: number, value: string) => {
  //   const newFields = [...idProofImage];
  //   newFields[index].idProof = value;
  //   setidProofImage(newFields);
  // }

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phoneNo", phone?.replace(country?.dialCode, ""));
    setPhoneCode(
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
  };

  useEffect(() => {
    setPhoneCode(dialCode || "");
    setProfilePicture(image || "");
  }, []);

  const [check, setCheck] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const handleChange = (event: any) => {
    if (event.target.value === value) {
      setValue("");
    } else {
      setValue(event.target.value);
    }
    setCheck(!check);
  };
  useEffect(() => {
    formik.setFieldValue("image", profile_picture);
  }, [profile_picture]);
  return (
    <div className="main_loyout">
      <div className="dashboard">
        {_id ? (
          <h1 className="mn_hdng">Edit Tutor</h1>
        ) : (
          <h1 className="mn_hdng">Add Tutor</h1>
        )}
        <Loader isLoad={isLoading} />
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-tutors");
          }}
        >
          Back
        </Button>
      </div>

      <Card className="cards">
        <form onSubmit={formik.handleSubmit}>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="custom_label">Image</Typography>
                {formik.values.image ? (
                  <div className="upload_image_preview">
                    <CardMedia
                      component="img"
                      image={formik.values.image}
                      alt="photo"
                    />
                    <CancelIcon
                      onClick={(e) => {
                        e.preventDefault();
                        setProfilePicture("");
                        formik.setFieldValue("profile_picture", "");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={handleImageUpload}
                      />
                      <Button component="span" className="upload_image_btn">
                        <img
                          src={
                            ByIdDetails?.image
                              ? ByIdDetails.image
                              : `/static/images/user_placeholder.png`
                          }
                          // src="/static/images/user_placeholder.png"
                          alt=""
                        />
                        <CameraAltIcon />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>
              {/* <Grid item xs={3}>
              <Typography className="custom_label">
                Account Status : Pending
              </Typography>
            </Grid> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Full Name</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  fullWidth
                  id="name"
                  placeholder="Full Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  helperText={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name.toString()
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">User Name</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"text"}
                  name="userName"
                  variant="outlined"
                  fullWidth
                  id="uername"
                  placeholder="User Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  helperText={
                    formik.touched.userName && formik.errors.userName
                      ? formik.errors.userName.toString()
                      : ""
                  }
                />
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Email</Typography>
                <TextField
                  hiddenLabel
                  type={"email"}
                  name="email"
                  variant="outlined"
                  fullWidth
                  placeholder="Email"
                  id="email"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email.toString()
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Phone Number</Typography>
                <PhoneInput
                  value={
                    (ByIdDetails?.dialCode ? ByIdDetails?.dialCode : dialCode) +
                    formik.values.phoneNo
                  }
                  country={"us"}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{ width: "100%" }}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneNo && formik.errors.phoneNo ? (
                  <h6 className="err_msg">
                    {formik.touched.phoneNo && formik.errors.phoneNo.toString()}
                  </h6>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Date Of Birth</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="age"
                  variant="outlined"
                  fullWidth
                  placeholder="Date Of Birth"
                  // id="lastName"
                  className="text_field"
                  // onChange={formik.handleChange}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.age}
                  helperText={
                    formik.touched.age && formik.errors.age
                      ? formik.errors.age.toString()
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Gender</Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedGender}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={(event) => {
                      handleGenderChange(event);
                      formik.setFieldValue("gender", event.target.value);
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                    {/* <MenuItem value="Other">Other</MenuItem> */}
                  </Select>
                </FormControl>
                {error && !selectedGender ? (
                  <div className="err_msg">Gender is required</div>
                ) : null}
              </Grid>
              {/* 
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">
                  Total Teaching Experience
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="totalTeachingExperience"
                  variant="outlined"
                  fullWidth
                  placeholder="Teaching Experience"
                  // id="lastName"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={ByIdDetails?.totalTeachingExperience?ByIdDetails?.totalTeachingExperience:formik.values.totalTeachingExperience}
                  helperText={
                    formik.touched.totalTeachingExperience &&
                    formik.errors.totalTeachingExperience
                      ? formik.errors.totalTeachingExperience.toString()
                      : ""
                  }
                />
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">
                  Select Education level
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={EducationLevelChange}
                    onChange={(event) => {
                      handleEducationLevelChange(event);
                      formik.setFieldValue(
                        "education_level",
                        event.target.value
                      );
                    }}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                  // onChange={handleProfessionChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1">Ph.d</MenuItem>
                    <MenuItem value="2">Masters</MenuItem>
                    <MenuItem value="3">Bachelors</MenuItem>
                  </Select>
                </FormControl>
                {error && !EducationLevelChange ? (
                  <div className="err_msg">Education level is required</div>
                ) : null}
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">
                  Select teaching options
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={SelectTeachingOption}
                    onChange={(event) => {
                      handleTeachingOptionChange(event);
                      formik.setFieldValue(
                        "teachingOption",
                        event.target.value
                      );
                    }}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1">Tutor Home</MenuItem>
                    <MenuItem value="2">Own Home</MenuItem>
                    
                  </Select>
                </FormControl>
                {error && !SelectTeachingOption ? (
                  <div className="err_msg">Teaching option is required</div>
                ) : null}
              </Grid> */}

              <Grid item xs={4}>
                <Typography className="custom_label">Address</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"text"}
                  name="address"
                  variant="outlined"
                  fullWidth
                  placeholder="Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  helperText={
                    formik.touched.address && formik.errors.address
                      ? formik.errors.address.toString()
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className="custom_label">Short Bio</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"text"}
                  name="shortBio"
                  variant="outlined"
                  fullWidth
                  placeholder="Short Bio"
                  multiline
                  maxRows={4}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shortBio}
                  helperText={
                    formik.touched.shortBio && formik.errors.shortBio
                      ? formik.errors.shortBio.toString()
                      : ""
                  }
                />
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12} />
              <Grid item lg={4} md={4} sm={6} xs={12} />
            </Grid>
            <div className="form_btn">
              <Button
                size="large"
                type="submit"
                className="btn btn_primary"
                onClick={() => setError(true)}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default ProfileSetup;
