import emptySplitApi from "../utils/rtk";
import { updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

 

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    
  
    addSettings: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.settings,
        method: "POST",
        body,
      }),
    }),
    displaysettings:builder.query<CommonResponseType &{data:any},{}>({
      query:()=>({
        url:END_POINTS.settings,
        method:'GET'
      })
    })
  
  }),
});

export const {
 
    useAddSettingsMutation,
    useLazyDisplaysettingsQuery
 
} = authApi;
