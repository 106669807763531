import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyArvvXN2SWXlO3Ij2cHrXlJak2KHPIqasY",
  authDomain: "tutorhail-c9fd4.firebaseapp.com",
  projectId: "tutorhail-c9fd4",
  storageBucket: "tutorhail-c9fd4.appspot.com",
  messagingSenderId: "831651786344",
  appId: "1:831651786344:web:d59a8afcf9a9fbfba315dd",
  measurementId: "G-HJ24KVMD3V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
