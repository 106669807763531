import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import { useAppSelector } from "./hooks/store";
import { getToken } from "./reducers/authSlice";
import { socket } from "./config/socket";
import { useEffect } from "react";

const Routing = () => {
 
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:_id" element={<UserDetails />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route path="/payment" element={<Pages.ManagePayment />} />
      <Route path="/paymentDetails/:_id" element={<Pages.PaymentDetails />} />

      <Route path="/paymentRequest" element={<Pages.ManagePaymentRequest />} />
      <Route path="/paymentRequestDetails/:_id" element={<Pages.PaymentRequestDetails />} />
      <Route
        path="/manage-tutor"
        element={<Pages.ManageServiceProvider />}
      />
      
      <Route
        path="/manage-tutor"
        element={<Pages.ManageServiceProvider />}
      />
      <Route
        path="/manage-tutor/details"
        element={<Pages.ServiceProviderDetails />}
      />
       <Route
        path="/manage-tutor/details/NextTutorDetail/:_id"
        element={<Pages.NextTutorDetail />}
      />
       
      <Route
        path="/manage-tutor/add"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-tutor/edit/:_id/"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
    
    
      <Route path="/manage-bookings" element={<Pages.ManageBookings />} />
      <Route
        path="/manage-bookings/details/:_id/"
        element={<Pages.BookingDetails />}
      />
       
      <Route path="/manage-reviews" element={<Pages.ManageReviews />} />
    
 
      <Route path="/manage-reviews/details/:_id" element={<Pages.ReviewDetails />} />

     
    
      {/* new */}

      <Route path="/manage-tutors" element={<Pages.ManageTutor />} />
      <Route path="/manage-tutors/details/:_id/" element={<Pages.TutorDetail />} />
      <Route path="/manage-tutors/add" element={<Pages.AddTutor />} />
      <Route path="/manage-tutors/edit/:_id/" element={<Pages.AddTutor />} />
      <Route path="/manage-settings" element={<Pages.Settings />} />
     {/* refund request */}
      <Route path="/refundRequest" element={<Pages.ManageRefundRequest />} />
      <Route path="/refundRequestDetails/:_id" element={<Pages.RefundRequestDetails />} />
      
      <Route
        path="/manage-tutor"
        element={<Pages.ManageServiceProvider />}
      />
    </Routes>
  );
};

export default Routing;
