import React, { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate, useParams } from "react-router-dom";
import { isValidInput } from "../../utils/validation";
import {
  useLazyBookingCsvQuery,
  useLazyGetAllBookingsQuery,
} from "../../services/booking";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/Loader";
import {
  formatDateTime,
  getOnlyDate,
  getOnlyTimeHHMM,
} from "../../utils/formatDateTime";
import testDateSeries from "../../utils/dateSeries";
import moment from "moment";

type props = {
  value: number;
};

const OngoingBookingsList = ({ value }: props) => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [fetchBooking] = useLazyGetAllBookingsQuery();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);

  function calculateTimeDifference(start: string, end: string): string {
    const startTime = new Date(start);
    const endTime = new Date(end);

    if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
      return "-";
    }

    const differenceInMillis: number = endTime.getTime() - startTime.getTime();
    const differenceInSeconds: number = differenceInMillis / 1000;

    if (differenceInSeconds < 0) {
      return "0 minute";
    }

    // Check if the difference is between 1 and 60 seconds
    if (differenceInSeconds > 0 && differenceInSeconds < 60) {
      return "1 minute";
    }

    const hours: number = Math.floor(differenceInSeconds / 3600);
    const minutes: number = Math.floor((differenceInSeconds % 3600) / 60);

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} and ${minutes} minute${
        minutes !== 1 ? "s" : ""
      }`;
    } else {
      return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    }
  }

  const fetchData = async (page: number, search: string) => {
    setLoading(true);
    try {
      const response = await fetchBooking({
        page,
        search,
        bookingType: 6,
      }).unwrap();
      setLoading(false);
      if (response.statusCode === 200) {
        setRows(response?.data?.booking);
        setTotalCount(response?.data?.totalBooking);
      } else {
        setRows([]);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);

  return (
    <div>
      <Loader isLoad={loading} />
      <Box className="cards_header">
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </Box>

      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Booking No</TableCell>
              <TableCell align="center">Customer Details</TableCell>
              <TableCell align="center">Tutor Details</TableCell>
              <TableCell align="center">Service Details</TableCell>
              <TableCell align="center">Pairing Time </TableCell>
              <TableCell align="center">Total Time </TableCell>
              <TableCell align="center">Start Date / Time</TableCell>
              <TableCell align="center">End Date / Time</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row: any, index: number) => (
              <TableRow key={row._id}>
                {/* Render table cells with booking data */}
                <TableCell align="center">
                  {(page - 1) * 10 + index + 1}
                </TableCell>
                <TableCell align="center">{row.bookingNumber}</TableCell>
                <TableCell>
                  <p style={{ margin: 0 }}>{row?.parents?.name || "-"}</p>
                  <p style={{ margin: 0 }}>{row?.parents?.email || "-"}</p>
                </TableCell>
                <TableCell>
                  <p style={{ margin: 0 }}>{row?.tutors?.name || "-"}</p>
                  <p style={{ margin: 0 }}>{row?.tutors?.email || "-"}</p>
                </TableCell>

                <TableCell>
                  {row?.subjectspecializations?.map(
                    (subject: any, subjectIndex: number) => (
                      <p key={subjectIndex} style={{ margin: 0 }}>
                        {subject?.name}
                      </p>
                    )
                  )}
                </TableCell>
                <TableCell>
                  {row?.bookingdetails?.length > 0
                    ? row?.bookingdetails?.map((item: any, index: number) => (
                        <div>
                          <p>
                            Date : {moment(item?.date).format("YYYY-MM-DD")}
                          </p>
                          <p>
                            Time :
                            {item?.classStart && item?.classEnd
                              ? moment(item?.classStart).format("HH:mm") +
                                " - " +
                                moment(item?.classEnd).format("HH:mm")
                              : "  -"}
                          </p>
                        </div>
                      ))
                    : ""}
                </TableCell>

                <TableCell>
                  {row?.bookingdetails?.length > 0
                    ? row?.bookingdetails?.map((item: any, index: number) => (
                        <p key={index}>
                          {calculateTimeDifference(
                            item?.classStart,
                            item?.classEnd
                          )}
                        </p>
                      ))
                    : ""}
                </TableCell>

                <TableCell>
                  {testDateSeries(row.bookingdetails) ? (
                    <div>
                      <p>from : {getOnlyDate(row.bookingdetails[0].date)} </p>
                      <p>
                        to :{" "}
                        {getOnlyDate(
                          row.bookingdetails[row.bookingdetails.length - 1].date
                        )}{" "}
                      </p>
                      <p>Time : </p>
                      <p>
                        {getOnlyTimeHHMM(row.bookingdetails[0].startTime)}
                        {/* {getOnlyTimeHHMM(row.bookingdetails[0].endTime)}{" "} */}
                      </p>
                    </div>
                  ) : (
                    row?.bookingdetails?.map(
                      (detail: any, detailIndex: number) => (
                        <div key={detailIndex}>
                          <p style={{ margin: 0 }}>
                            Date : {getOnlyDate(detail.date)}
                          </p>
                          <p style={{ margin: 0 }}>
                            Time : {getOnlyTimeHHMM(detail.startTime)}
                            {/* {getOnlyTimeHHMM(detail.endTime)} */}
                          </p>
                          <p></p>
                        </div>
                      )
                    )
                  )}
                </TableCell>
                <TableCell>
                  {testDateSeries(row.bookingdetails) ? (
                    <div>
                      <p>from : {getOnlyDate(row?.bookingdetails[0]?.date)} </p>
                      <p>
                        to :{" "}
                        {getOnlyDate(
                          row.bookingdetails[row?.bookingdetails?.length - 1]
                            .date
                        )}{" "}
                      </p>
                      <p>Time : </p>
                      <p>
                        {/* {getOnlyTimeHHMM(row.bookingdetails[0].startTime)} -{" "} */}
                        {getOnlyTimeHHMM(row?.bookingdetails[0]?.endTime)}
                      </p>
                    </div>
                  ) : (
                    row.bookingdetails.map(
                      (detail: any, detailIndex: number) => (
                        <div key={detailIndex}>
                          <p style={{ margin: 0 }}>
                            Date : {getOnlyDate(detail.date)}
                          </p>
                          <p style={{ margin: 0 }}>
                            Time : {/*  {getOnlyTimeHHMM(detail.startTime)}- */}
                            {getOnlyTimeHHMM(detail.endTime)}
                          </p>
                          <p></p>
                        </div>
                      )
                    )
                  )}
                </TableCell>

                <TableCell>
                  <Box className="table_actions">
                    <IconButton
                      onClick={() =>
                        navigate(`/manage-bookings/details/${row?._id}`, {
                          state:
                            value === 1
                              ? "CANCELLED"
                              : value === 2
                                ? "ONGOING"
                                : "COMPLETED",
                        })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default OngoingBookingsList;
