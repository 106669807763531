import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "./Layout.scss";
import { SIDEBAR_WIDTH } from "../helpers/constants/SideBarWidth";
import { STORAGE_KEYS, getFromStorage, removeFromStorage } from "../helpers";
import { setCredentials } from "../reducers/authSlice";
import { useAppDispatch } from "../hooks/store";
import { useLazyGetUserQuery, usePostLogoutMutation } from "../services/auth";
import useAuth from "../hooks/useAuth";
import { LogoutModal } from "../components";
import { useLazyDeleteNotificationQuery, useLazyGetReviewerNotificationsQuery } from "../services/notifications";
import { Padding } from "@mui/icons-material";

interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  
console.log("init",getFromStorage(STORAGE_KEYS.token));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getUser] = useLazyGetUserQuery();
  const [logoutMethod, { isLoading }] = usePostLogoutMutation();

  const [openLogout, setOpenLogout] = useState<boolean>(false);

  const user = useAuth();
  console.log("user: ", user);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  // const navigate = useNavigate();
  const[getAllNotifications]=useLazyGetReviewerNotificationsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  // const { _id } = useParams();
  // const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
   const [deleteNotificationById] = useLazyDeleteNotificationQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
const[rows,setRows]=useState<any>([]);
  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);

  // const rows = [createData("Abdul Hussian", "Title", "Message")];
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await getAllNotifications({ page, search }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.notification||[]);
  
        setTotalCount(response?.data?.totalNotification);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if(user){
      fetchData(page, debouncedSearchTerm);
    }
  }, [page, debouncedSearchTerm, user]);

  const handleLogout = async () => {
    try {
      const res = await logoutMethod({}).unwrap();
      navigate("/");
      removeFromStorage(STORAGE_KEYS.token);
      removeFromStorage(STORAGE_KEYS.tempToken);
      dispatch(
        setCredentials({
          user: null,
          token: null,
        })
      );
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };
  
// console.log('toke topbar',toke)
  const getUserDetails = async () => {
    const token = await getFromStorage(STORAGE_KEYS.token);
console.log("topbar token",token);
    if (token) {
      try {
        const result = await getUser({}).unwrap();
        if (result?.statusCode === 200 && token) {
          dispatch(
            setCredentials({
              user: result?.data,
              token: token,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <Box className="container top_bar">
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          ml: { lg: `${SIDEBAR_WIDTH}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleSidebarToggle}
            style={{ backgroundColor: "#72cedd" }}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ mr: 2 }}>
              <IconButton
                className="notification_btn"
                aria-controls={open1 ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                <NotificationsActiveIcon />
              </IconButton>
              <Menu
                className="notiDropdown_menu"
                id="basic-menu1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="noti_head">
                  <Typography component="h2" style={{paddingLeft:35}}>Notifications</Typography>
                  <Button
                  style={{marginLeft:20}}
                      className="btn btn_primary sm"
                      onClick={() => navigate("/recieved-notifications")}
                     
                    >
                        See All
                      </Button>
                </Box>
                <ul className="noti_list">
                {rows.slice(0,3)?.length > 0 ? (
  <><ul className="noti_list">
                      {rows.slice(0, 3)?.map((notification: any, index: number) => (
                        <li key={index}>
                          <div className="noti_info">
                            <Typography component="h3">{notification.title}</Typography>
                            <Typography component="p">{notification.message}</Typography>
                          </div>
                        </li>

                      ))}
                    </ul>
                    {/* <Button
                      className="btn btn_primary sm"
                      onClick={() => navigate("/recieved-notifications")}
                     
                    >
                        See All
                      </Button> */}
                      </>
) : (
  <Typography style={{padding:10}}>No notification received</Typography>
)}

</ul>

              </Menu>
            </Box>

            <Box>
              <IconButton
                className="topbar_user_btn"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  src={
                    user?.image
                      ? user?.image
                      : "/static/images/user_placeholder.png"
                  }
                  alt=""
                />
                <span>
                  {(user?.firstName || "Admin") + " " + (user?.lastName || "")}
                </span>
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu
                className="userDropdown_menu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/profile")}
                >
                  <IconButton className="drpdwn_icns">
                    <PersonIcon />
                  </IconButton>
                  My Profile
                </MenuItem>
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/changepassword")}
                >
                  <IconButton className="drpdwn_icns">
                    <LockResetIcon />
                  </IconButton>
                  Change Password
                </MenuItem>

                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => setOpenLogout(true)}
                >
                  <IconButton className="drpdwn_icns">
                    <LogoutIcon />
                  </IconButton>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <LogoutModal
        openLogout={openLogout}
        setOpenLogout={setOpenLogout}
        handleLogout={handleLogout}
      />
    </Box>
  );
}

export default Topbar;
