import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetInvoiceByIdQuery, useLazyGetPaymentByIduserQuery } from "../../services/booking";
import Loader from "../../helpers/Loader";
import { errorToast, successToast } from "../../helpers";

const PaymentRequestDetails = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [getPaymentById, { isLoading }] = useLazyGetPaymentByIduserQuery();
  const[fetchgenerateInvoice] = useLazyGetInvoiceByIdQuery();

  const generateIvoice = async() =>{
    try{
      const response = await fetchgenerateInvoice({userId:_id}).unwrap();
      if(response?.statusCode===200)
      {
        window.open(response?.data?.data||"");
        successToast("Invoice generated successfully")
      }
       
    }
    catch(e:any)
    {
      errorToast(e?.data?.message||"");
    }
  }
  useEffect(() => {
    const fetchDataById = async () => {
      try {
        const response = await getPaymentById({ userId: _id }).unwrap();
        if (response.statusCode === 200) {
          setByIdDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching payment details:", error);
      }
    };

    if (_id) {
      fetchDataById();
    }
  }, [_id, getPaymentById]);

  return (
    <MainContainer>
        <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Payment Details</h1>
          <Grid item xs={12} style={{paddingRight:40}}>
                      <Button className="btn btn_primary" onClick={generateIvoice}>
                        Generate Invoice
                      </Button>
                    </Grid>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/payment");
            }}
          >
            Back
          </Button>
        </div>
        {ByIdDetails && (
          <Card className="cards">
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2} className="view_box">
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Booking Id</Typography>
                        <Typography component="p">
                          {ByIdDetails?.bookingNumber || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Transaction Id</Typography>
                        <Typography component="p">
                         
                          {ByIdDetails?.OrderTrackingId || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Total Amount</Typography>
                        <Typography component="p">
                         
                          {`$ `+ByIdDetails?.grandTotal?.toFixed(2) || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Payment Received</Typography>
                        <Typography component="p"> {ByIdDetails?.OrderTrackingId?"Yes":"No"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Customer Name</Typography>
                        <Typography component="p">
                          {ByIdDetails?.parents[0]?.name || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Email</Typography>
                        <Typography component="p">
                          {ByIdDetails?.parents[0]?.email || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Phone Number</Typography>
                        <Typography component="p">
                          {ByIdDetails?.parents[0]?.phoneNo || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="h5">Subjects</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Subject Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ByIdDetails.subjects.map((subject: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{subject.name}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="h5">Tutors</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone Number</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ByIdDetails.tutors.map((tutor: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{tutor.name}</TableCell>
                                  <TableCell>{tutor.email}</TableCell>
                                  <TableCell>{tutor.phoneNo}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="h5">Booking Details</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ByIdDetails.bookingdetails.map((booking: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{new Date(booking.date).toLocaleDateString()}</TableCell>
                                  <TableCell>{new Date(booking.startTime).toLocaleTimeString()}</TableCell>
                                  <TableCell>{new Date(booking.endTime).toLocaleTimeString()}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
           
      </div>
    </MainContainer>
  );
};

export default PaymentRequestDetails;
