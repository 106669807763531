 
import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody, TutorInfo } from "../types/General";

type CommonResponseType = {
  data: any;
  statusCode: number;
  message: string;
};

type PostLoginBody = {
  email: string;
  password: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    
   
    
  
    addBankInfo: builder.mutation<CommonResponseType  & { data: TutorInfo }, CommonBody>({
      query: (body) => ({
        url: END_POINTS.addBankDetails,
        method: "POST",
        body,
      }),
    }),
   

     
      PutupdateBank: builder.mutation<CommonResponseType, { body: any; userId: any }>({
        query: ({ body, userId }) => ({
          url: `${END_POINTS.updateBankDetails}/${userId}`, 
          method: "PUT",
          body,
        }),
  
    }),
    
  
  }),
  

 
   
  
});

export const {
  useAddBankInfoMutation,
  usePutupdateBankMutation,
    
   
} = authApi;
