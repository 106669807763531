import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/modals/WarnModal";
import SearchBar from "../../components/SearchBar";
import Loader from "../../helpers/Loader";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetExportCsvQuery,
  useLazyDeleteByIduserQuery,
  useLazyGetAllUserQuery,
  useEditUserMutation,
} from "../../services/users";
import { errorToast, successToast } from "../../helpers";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const ManageUsers = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [exportCsv] = useLazyGetExportCsvQuery();

  const [getallusers, { isLoading }] = useLazyGetAllUserQuery();
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [deleteUserById] = useLazyDeleteByIduserQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [editUSERbyId] = useEditUserMutation();
  const navigate = useNavigate();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const fetchData = async (page: number, search: string) => {
    try {
      const response = await getallusers({ page, search }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.parent);
        setTotalCount(response?.data?.totalParent);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeUserStatus = async (tutorId: string, tutorStatus: boolean) => {
    try {
      const body = {
        isBlocked: String(tutorStatus),
      };
      console.log("user1111", body);

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      const response = await editUSERbyId({
        body: encryptedBody,
        id: tutorId,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Status updated successfully");
      }
    } catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({}).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  // const handleExportCsv = async () => {
  //   try {
  //     const response = await exportCsv({}).unwrap();
  //     console.log("Response:", response);

  //     if (typeof response === 'string') {
  //       const responseData = JSON.parse(response);
  //       if (responseData?.statusCode === 200 && responseData?.message?.s3Location) {
  //         window.open(responseData?.message?.s3Location);
  //       } else {
  //         errorToast("Error: File not found");
  //       }
  //     } else {
  //       errorToast("Error: Unexpected response format");
  //     }
  //   } catch (error: any) {
  //     errorToast(error?.message);
  //   }
  // };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);

  const handleDeleteUser = async (userId: any) => {
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("User deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Users</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Phone No.</TableCell>
                  <TableCell>Email</TableCell>

                  <TableCell>Account Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, i: any) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>{row.name || "-"}</TableCell>
                    <TableCell>
                      {row?.dialCode && row.phoneNo
                        ? row?.dialCode + row.phoneNo
                        : "-"}
                    </TableCell>
                    <TableCell>{row.email || "-"}</TableCell>
                    <TableCell>
                      <Switch
                        defaultChecked={!row.isBlocked}
                        onChange={(e) => {
                          const isChecked = !e.target.checked;
                          changeUserStatus(row._id, isChecked);
                        }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(`/manageusers/userdetails/${row._id}`)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate(`/manageusers/usersform/${row?._id}`)
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteUser(selectedId)}
        name="User"
      />
    </MainContainer>
  );
};

export default ManageUsers;
