import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Select,
  MenuItem,
  Typography,
  Checkbox,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  useLazyDeleteTutorByIdQuery,
  useLazyGetAllTutorListingQuery,
  useLazyTutorCsvQuery,
} from "../../services/tutor";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { successToast, errorToast } from "../../helpers";
import Loader from "../../helpers/Loader";
import WarnModal from "../../components/modals/WarnModal";
import Pagination from "../../components/Pagination";
import { usePutTutorProfileMutation } from "../../services/tutor";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validation";

const Accepted = ({}: any) => {
  const [exportCsv] = useLazyTutorCsvQuery();
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [stateofTutor, setstateofTutor] = useState<{ [key: string]: number }>(
    {}
  );
  const [deleteById] = useLazyDeleteTutorByIdQuery();
  const [updateTutorStatus] = usePutTutorProfileMutation();
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / 10);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const [rows, setRows] = useState<any>([]);
  const [value, setValue] = useState<number>(1);
  const [tututorStatust, settututorStatust] = useState<number>(2);
  const [getAllTutorList, { isLoading }] = useLazyGetAllTutorListingQuery();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  const handleRowCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ) => {
    if (event.target.checked) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== rowId)
      );
    }
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setSelectedRows(
      event.target.checked ? rows.map((r: { _id: any }) => r._id) : []
    );
  };

  const handleReject = async () => {
    if (selectedRows.length === 0) {
      errorToast("Please select at least one row to reject.");
      return;
    }
    await changeUserStatus(selectedRows, "reject");
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      errorToast("Please select at least one row to delete.");
      return;
    }
    await changeUserStatus(selectedRows, "delete");
  };

  const fetchRejectedTutor = async (
    page: number,
    search: string,
    tutorStatus: number
  ) => {
    try {
      const response = await getAllTutorList({
        page,
        search,
        tutorStatus: String(tutorStatus),
      }).unwrap();
      if (response.statusCode === 200) {
        setRows(response.data?.tutor || []);
        setTotalCount(response?.data?.totalTutor);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchApi = async (tutorId: string[], tutorStatus: boolean) => {
    try {
      const body = {
        ids: tutorId,
        status: "tutorUpdate",
        isBlocked: String(tutorStatus),
      };
      console.log(body, "body in toggle");
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      const response = await updateTutorStatus({ encryptedBody }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Tutor Account Status updated successfully");
      }
    } catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  };

  const changeUserStatus = async (tutorId: string[], tutorStatus: string) => {
    try {
      const body = {
        status: tutorStatus,
        ids: tutorId,
      };
      console.log("-----99998", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      const response = await updateTutorStatus({ encryptedBody }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Tutor State updated successfully");
        fetchRejectedTutor(page, debouncedSearchTerm, tututorStatust);
      }
    } catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  };

  const handleDeleteTutor = async (userId: any) => {
    try {
      const response = await deleteById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Tutor deleted successfully");
        fetchRejectedTutor(page, searchTerm, tututorStatust);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({}).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("Csv exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  useEffect(() => {
    if (value === 1 && tututorStatust === 2) {
      fetchRejectedTutor(page, searchTerm, tututorStatust);
    }
  }, [page, searchTerm, tututorStatust, value]);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <Box className="cards_header">
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
        <Box className="cards_header_right">
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "black",
                  color: "#ffffff",
                  marginLeft: "10px",
                }}
                onClick={handleReject}
              >
                Reject
              </Button>
            </>
          </div>
          <Button className="btn btn_primary" onClick={handleExportCsv}>
            <FileDownloadIcon /> Export CSV
          </Button>

          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-tutor/add")}
          >
            <AddIcon />
            Add Tutor
          </Button>
        </Box>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all rows" }}
                />
              </TableCell>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Full Name</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Contact Number</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Request</TableCell>
              <TableCell align="center">Account Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No tutor found
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row: any, i: any) => (
                <TableRow key={row?._id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(row._id)}
                      onChange={(event) =>
                        handleRowCheckboxClick(event, row._id)
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    {(page - 1) * 10 + i + 1}
                  </TableCell>
                  <TableCell>{row?.name ? row?.name : "-"}</TableCell>
                  <TableCell>{row.userName ? row?.userName : "-"}</TableCell>
                  <TableCell>{row?.dialCode + row.phoneNo}</TableCell>
                  <TableCell>{row.email ? row?.email : "-"}</TableCell>
                  <TableCell align="center">
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={stateofTutor[row._id] || ""}
                      onChange={(e) => {
                        const selectedOption: any = e.target.value;
                        setstateofTutor((prevState) => ({
                          ...prevState,
                          [row._id]: selectedOption,
                        }));
                        changeUserStatus([row?._id], selectedOption);
                      }}
                      displayEmpty
                    >
                      <MenuItem value="">Approved</MenuItem>
                      <MenuItem value="reject">Reject</MenuItem>
                      {/* <MenuItem value="1">Pending</MenuItem> */}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Switch
                      defaultChecked={!row.isBlocked}
                      onChange={(e) => {
                        const isChecked = !e.target.checked;
                        fetchApi([row._id], isChecked);
                      }}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate(`/manage-tutors/details/${row._id}`, {
                            state: { userStatus: "PENDING" },
                          })
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          navigate(`/manage-tutors/edit/${row._id}`)
                        }
                      >
                        <ModeEditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOpen(true);
                          setSelectedId(row?._id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteTutor(selectedId)}
        name="Tutor"
      />
    </div>
  );
};

export default Accepted;
