import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { generateResponsiveStyle } from "../../utils/modalStyle";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
};

const RefundReasonModal = ({
  open,
  setOpen,
  handleDelete,
  reason,
  setReason,
}: props) => {
  const style = generateResponsiveStyle();
  const isReasonEmpty = reason.trim() === "";

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontSize: "19px" }}
        >
          Write a reason why you are rejecting the refund request.
        </Typography>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextField
            className="text_field"
            hiddenLabel
            type={"text"}
            name="cancelReason"
            variant="outlined"
            fullWidth
            id="name"
            placeholder="Enter a reason"
            value={reason}
            onChange={(val) => setReason(val.target.value)}
          />
        </Grid>
        <div className="flexdiv">
          <Button
            style={{ color: "white" }}
            className="noBtn"
            disabled={isReasonEmpty}
            onClick={() => {
              setOpen(false);
              setReason("");
              handleDelete();
            }}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default RefundReasonModal;
