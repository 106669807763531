import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser={
  page:number,
  search:string
}
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // postLogIn: builder.mutation<
    //   CommonResponseType & { data: User },
    //   CommonBody
    // >({
    //   query: (body) => ({
    //     url: END_POINTS.login,
    //     method: "POST",
    //     body,
    //   }),
    // }),
    getAllUser: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string }>({
      query: ({ page, search }) => {
        let url = END_POINTS.getParent;
        if (page && search) {
          url += `?page=${page}&search=${search}`;
        } else if (page) {
          url += `?page=${page}`;
        } else if (search) {
          url += `?search=${search}`;
        }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    
    
   
    getSpecificUserById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.getParent}/${userId}/`,
        method: "GET"
      })
    }),
    getBookingDetailsById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.parentBooking}/${userId}/`,
        method: "GET"
      })
    }),
    getUserBookingDetailsById: builder.query<CommonResponseType & { data: any }, { userId: any,bookingType: any}>
    ({
      query: ({ userId,bookingType }) => ({
        url: `${END_POINTS.parentBooking}/${userId}?bookingType=${bookingType}`,
        method: "GET"
      })
    }),
    getActivityHistoryById: builder.query<CommonResponseType & { data: any }, { userId: any, bookingType: any }>
    ({
      query: ({ userId, bookingType }) => ({
        url: `${END_POINTS.parentBooking}/${userId}/?bookingType=${bookingType}`,
        method: "GET"
      })
    }),
    
    getBankDetailsbYId: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.getBankDetails}/${userId}`,
        method: "GET"
      })
    }),
    getExportCsv: builder.query<CommonResponseType & { data: any }, {}>
    ({
      query: ({  }) => ({
        url: `${END_POINTS.parentExport}`,
        method: "GET"
      })
    }),
    // getUserDetailsbYId: builder.query<CommonResponseType & { data: any }, { userId: any}>
    // ({
    //   query: ({ userId }) => ({
    //     url: `${END_POINTS.getParent}/${userId}`,
    //     method: "GET"
    //   })
    // }),
  
    EditUser: builder.mutation<
    CommonResponseType & { data: CommonBody },
    { id: string | undefined; body: CommonBody }
  >({
    query: ({ body, id }) => ({
      url: `${END_POINTS.updateParent}/${id}`,
      method: "PUT",
      body,
    }),
  }),
  
    
      
    deleteByIduser:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.deleteParent}/${userId}/`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
    useLazyGetAllUserQuery,
    useLazyGetSpecificUserByIdQuery,
    useEditUserMutation,
    useLazyDeleteByIduserQuery,
    useLazyGetExportCsvQuery,
    useLazyGetBankDetailsbYIdQuery,
    useLazyGetBookingDetailsByIdQuery,
    useLazyGetActivityHistoryByIdQuery,
    useLazyGetUserBookingDetailsByIdQuery
} = authApi;
