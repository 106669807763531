import { getOnlyDate } from "./formatDateTime";

export default function testDateSeries(data:any){
    if(!data) return;
    let isSeries=true;
    if(data.length>1)
    {
    for(let i=0;i<data.length-1;i++)
    {
        const date1:any=new Date(getOnlyDate(data[i].date));
         const date2:any=new Date(getOnlyDate(data[i+1].date));
        if((date2-date1)!=86400000)
        { 
          // console.log("DATE IS NOT IN SERIES");
          isSeries=false;
          break;
        }
    }
    
    if(isSeries)
    {
      if(isTimeSlotSame(data))
      return true;
      // console.log("DATE IS IN SERIES");
    }
    }
      return false;
    }

function isTimeSlotSame(data:any){
    let isTimeSlotSame=true;
    for(let i=0;i<data.length-1;i++)
    {
      // console.log((data[i].startTime!=data[i+1].startTime));
      // console.log((data[i].endTime!=data[i+1].endtime));
        if((data[i].startTime!=data[i+1].startTime) || (data[i].endTime!=data[i+1].endTime))
        {
            console.log(data[i]);
            console.log(data[i+1])
            isTimeSlotSame=false;
            break;
        }
    }
    if(isTimeSlotSame)
        {
            return true;
        }

return false;
}