import emptySplitApi from "../utils/rtk";
import { updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type ExportParams={
  query:any,
type :any
}
 
export const settingsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
      
      exportUserCsv: builder.query<CommonResponseType & { data: any }, ExportParams>({
        query: ({ type, query }) => {
          let url = END_POINTS.userGraph;
          if (type && query) {
            url += `?type=${type}&export=${query}`;
          }  
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      exportTutorCsv: builder.query<CommonResponseType & { data: any }, ExportParams>({
        query: ({ type, query }) => {
          let url = END_POINTS.tutorGraph;
          if (type && query) {
            url += `?type=${type}&export=${query}`;
          }  
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      
      exportBookingCsv: builder.query<CommonResponseType & { data: any }, ExportParams>({
        query: ({ type, query }) => {
          let url = END_POINTS.bookingGraph;
          if (type && query) {
            url += `?type=${type}&export=${query}`;
          }  
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      
      exportRevenueCsv: builder.query<CommonResponseType & { data: any }, ExportParams>({
        query: ({ type, query }) => {
          let url = END_POINTS.earningGraph;
          if (type && query) {
            url += `?type=${type}&export=${query}`;
          }  
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      

      // ???????????
      GetGraphUserGraph: builder.query<CommonResponseType & { data: any },string >({  
        query: (type) => ({ 
          url: `${END_POINTS.userGraph}?type=${type}`,  
          method: "GET",
        }),
      }), 
      GetEarning: builder.query<CommonResponseType & { data: any }, string>({
        query: (type) => ({
          url: `${END_POINTS.earningGraph}?type=${type}`,
          method: "GET",
        }),
      }),
      
      GetGraphTutorGraph: builder.query<CommonResponseType & { data: any },   string >({  
        query: (type) => ({ 
          url: `${END_POINTS.tutorGraph}?type=${type}`,  
          method: "GET",
        }),
      }), 

      GetTheBookingGraph: builder.query<CommonResponseType & { data: any },string>({  
        query: (type) => ({ 
          url: `${END_POINTS.bookingGraph}?type=${type}`,  
          method: "GET",
        }),
      }), 
      GetDashboArdApi: builder.query<CommonResponseType & { data: any },{}>({
        query: () => ({
          url: `${END_POINTS.dashboard}`,
          method: "GET",
        }),
      }), 

    }),
  });

  

export const {  
  useLazyExportUserCsvQuery,
  useLazyExportTutorCsvQuery,
  useLazyExportBookingCsvQuery,
  useLazyExportRevenueCsvQuery,
  useLazyGetGraphUserGraphQuery,
    useLazyGetEarningQuery,
    useLazyGetGraphTutorGraphQuery,
    useLazyGetTheBookingGraphQuery,
    useLazyGetDashboArdApiQuery
 } = settingsApi;
