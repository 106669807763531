import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type PostLoginBody = {
  email: string;
  password: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogIn: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    getUser: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.get_user,
        method: "GET",
      }),
    }),
    postLogout: builder.mutation<CommonResponseType, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "GET",
      }),
    }),
    forgotPassword: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),
    postVerifyOtp: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    putUpdateProfile: builder.mutation<
    CommonResponseType & { data: updateProfile },
    CommonBody
  >({
    query: (body) => ({
      url: END_POINTS.updateProfile,
      method: "PUT",
      body,
    }),
  }),
    postChangePassword: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePostLogInMutation,
  useLazyGetUserQuery,
  usePostLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  usePostVerifyOtpMutation,
  usePostChangePasswordMutation,
  usePutUpdateProfileMutation
} = authApi;
