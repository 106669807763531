import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser={
  page:number,
  search:string
}
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
   
    getAllreviewIndex: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string }>({
      query: ({ page, search }) => {
        let url = END_POINTS.review;
        if (page && search) {
          url += `?page=${page}&search=${search}`;
        } else if (page) {
          url += `?page=${page}`;
        } else if (search) {
          url += `?search=${search}`;
        }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    
    
   
    getSpecificReviewById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.tutorReview}/${userId}`,
        method: "GET"
      })
    }),
   
    getSpecificReviewPageById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.review}/${userId}`,
        method: "GET"
      })
    }),
   
  
    
      
    deleteByIdReview:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.deleteReview}/${userId}`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
    useLazyGetAllreviewIndexQuery,
    useLazyGetSpecificReviewByIdQuery,
    useLazyGetSpecificReviewPageByIdQuery,
    useLazyDeleteByIdReviewQuery,
 
    
} = authApi;
