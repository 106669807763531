import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody, TutorInfo } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    UpdateIdProof: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody; userId: string | undefined }
    >({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.addDocuments}/${userId}`,
        method: "POST",
        body,
      }),
    }),
    getIdProofById: builder.query<
      CommonResponseType & { data: any },
      { userId: any; documentType?: number }
    >({
      query: ({ userId, documentType }) => ({
        url: `${END_POINTS.getDocuments}/${userId}`,
        method: "GET",
        params: { documentType }, // Include documentType as a query parameter
      }),
    }),

    getDocucmentsDetails: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.getDocuments}/${userId}`,
        method: "GET",
        // Include documentType as a query parameter
      }),
    }),

    PostaddDocuments: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: `${END_POINTS.addDocuments}`,
        method: "POST",
        body,
      }),
    }),

    PutUpdateDocs: builder.mutation<
      CommonResponseType & { data: any },
      { documentType?: number; id: number; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateDocuments}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteDocsById: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.deleteDocuments}/${userId}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  usePostaddDocumentsMutation,
  useUpdateIdProofMutation,
  useLazyDeleteDocsByIdQuery,
  useLazyGetIdProofByIdQuery,
  useDeleteDocsByIdQuery,
  useLazyGetDocucmentsDetailsQuery,
  usePutUpdateDocsMutation,
} = authApi;
