import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Rating } from "react-simple-star-rating";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyDeleteByIdReviewQuery,
  useLazyGetAllreviewIndexQuery,
} from "../../services/review";
import { useLazyGetExportCsvQuery } from "../../services/users";
import Pagination from "../../components/Pagination";
import { errorToast, successToast } from "../../helpers";
import WarnModal from "../../components/modals/WarnModal";
import Loader from "../../helpers/Loader";

const ManageReviews = () => {
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [fetchReview, { isLoading }] = useLazyGetAllreviewIndexQuery();
  const navigate = useNavigate();
  const [deleteReviewById] = useLazyDeleteByIdReviewQuery();
  const [tutor, setTutor] = useState<any>([]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const handleDeleteReview = async (userId: any) => {
    setLoading(true);
    try {
      const response = await deleteReviewById({ userId }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        successToast("Review deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  let totalPages = Math.ceil(totalCount / 10);
  const fetchData = async (page: number, search: string) => {
    setLoading(true);
    try {
      const response = await fetchReview({ page, search }).unwrap();
      setLoading(false);
      if (response.statusCode === 200) {
        const data = response?.data;
        setRows(data?.rating || []);
        setTotalCount(data?.totalRating || 0);
        if (data?.rating && data.rating.length > 0) {
          setTutor(data.rating[0]?.tutors[0]?.name);
        }
      } else {
        setRows([]);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Reviews</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Booking Id</TableCell>
                    <TableCell align="center">Tutor Name</TableCell>
                    <TableCell align="center">Rating By</TableCell>
                    <TableCell>Ratings</TableCell>
                    <TableCell>Reviews</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any, index: any) => (
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell align="center">
                        {row?.bookings[0]?.bookingNumber || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.tutors[0]?.name || "-"}
                      </TableCell>
                      <TableCell>{row?.parents[0]?.name || "-"}</TableCell>
                      <TableCell>
                        {" "}
                        {/* <TableCell> */}
                        <Rating
                          initialValue={row.rating}
                          iconsCount={5}
                          size={23}
                          allowHover={false}
                          readonly
                        />
                        {/* </TableCell> */}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={row?.review || "-"}>
                          <span>{row?.review || "-"}</span>
                        </Tooltip>
                      </TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-reviews/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteReview(selectedId)}
        name="Review"
      />
    </MainContainer>
  );
};

export default ManageReviews;
