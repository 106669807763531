import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";
import { useNavigate } from "react-router-dom";
import { SOCKET_URL, socket } from "./config/socket";
import { useAppSelector } from "./hooks/store";
import { getToken } from "./reducers/authSlice";

function App() {
  const socketToken: any = useAppSelector(getToken);

  const navigate = useNavigate();
  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("FCM", fcmToken);
          localStorage.setItem("fcmToken", fcmToken as string);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          console.log("Dddddddddddddddddddddddd");
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.notification?.title}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.notification?.body}
              </div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  const onConnect = () => {
    console.log("Socket connected");
  };

  const onDisconnect = () => {
    console.log("Socket disconnected");
  };

  const onError = (error: any) => {
    console.error("Socket connection error:", error);
  };

  useEffect(() => {
    if (socketToken?.length) {
      console.log(socketToken, "lllll");

      const modifiedURL = `${SOCKET_URL}?token=${socketToken || ""}`;
      console.log(modifiedURL, "mofiy");
      socket.io.uri = modifiedURL;

      if (!socket?.connected) {
        console.log("abc11");
        socket.connect();
      }

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);

      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    }
  }, [socketToken]);
  return <Routing />;
}

export default App;
