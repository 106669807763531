import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Accepted from "../../features/tutor/Accepted";
import Rejected from "../../features/tutor/Rejected";
import Pending from "../../features/tutor/Pending";
import {
  useLazyDeleteTutorByIdQuery,
  useLazyGetAllTutorListingQuery,
} from "../../services/tutor";
import { errorToast, successToast } from "../../helpers";
import { useLazyTutorCsvQuery } from "../../services/tutor";
import Loader from "../../helpers/Loader";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageTutor = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [value, setValue] = useState<number>(1);
  const [tututorStatust, settututorStatust] = useState<number>(1);
  const [rows, setRows] = useState<any>([]);
  const [getAllTutorList, { isLoading }] = useLazyGetAllTutorListingQuery();
  const { _id } = useParams(); // Accessing the _id parameter from the URL
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      settututorStatust(2);
    } else if (newValue === 1) {
      settututorStatust(1);
    } else if (newValue === 2) {
      settututorStatust(3);
    }
  };

  const fetchAcceptedTutor = async (
    page: number,
    search: string,
    tutorStatus: number
  ) => {
    try {
      const response = await getAllTutorList({
        page,
        search,
        tutorStatus: String(tutorStatus),
      }).unwrap();
      if (response.statusCode === 200) {
        setRows(response.data?.tutor);
        setTotalCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAcceptedTutor(page, searchTerm, tututorStatust);
  }, [page, searchTerm, tututorStatust]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Tutors</h1>
        </div>
        <Card className="cards">
          <Loader isLoad={isLoading} />
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Approved" {...a11yProps(0)} />
                <Tab label="Pending" {...a11yProps(1)} />
                <Tab label="Rejected" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Accepted
                rows={rows}
                page={page}
                setRows={setRows}
                setPage={setPage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Pending
                rows={rows}
                page={page}
                setRows={setRows}
                setPage={setPage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Rejected
                rows={rows}
                page={page}
                setRows={setRows}
                setPage={setPage}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageTutor;
